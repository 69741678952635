import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PropTypes from 'prop-types';
import amountBlockStyles from './styles';

const AmountBlock = ({ amount }) => {
  const classes = amountBlockStyles();

  return (
    <Box>
      <Box mt={2} mb={1}>
        <Typography className={classes.text}>
          Amount
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box className={classes.blockDollar}>
          <MonetizationOnIcon className={classes.iconDollar} fontSize="small" />
        </Box>
        <Box display="flex">
          <Typography variant="h6" color="secondary">
            $
            {amount.toLocaleString()}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

AmountBlock.propTypes = {
  amount: PropTypes.number.isRequired,
};

export default AmountBlock;
