import MuiMenu from 'theme/overrides/MuiMenu';
import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';
import MuiFilledInput from './MuiFilledInput';
import MuiFormLabel from './MuiFormLabel';
import MuiInputLabel from './MuiInputLabel';
import MuiRadio from './MuiRadio';
import MuiSwitch from './MuiSwitch';
import MuiSlider from './MuiSlider';
import MuiTableRow from './MuiTableRow';
import MuiTablePagination from './MuiTablePagination';
import MuiTable from './MuiTable';
import MuiCheckbox from './MuiCheckbox';
import MuiTextField from './MuiTextField';
import MuiDialog from './MuiDialog';
import MuiDialogTitle from './MuiDialogTitle';
import MuiLink from './MuiLink';
import MuiDialogContent from './MuiDialogContent';
import MuiMenuItem from './MuiMenuItem';
import MuiSelect from './MuiSelect';
import MuiPaginationItem from './MuiPaginationItem';
import MuiPagination from './MuiPagination';
import MuiSkeleton from './MuiSkeleton';
import MuiPickersPopper from './MuiPickersPopper';
import MuiPickersArrowSwitcher from './MuiPickersArrowSwitcher';
import MuiPickersDay from './MuiPickersDay';
import MuiPickersDesktopDateRangeCalendar from './MuiPickersDesktopDateRangeCalendar';
import MuiChip from './MuiChip';
import MuiInputBase from './MuiInputBase';
import MuiPickersDateRangeDay from './MuiPickersDateRangeDay';
import MuiAccordion from './MuiAccordion';
import MuiAutocomplete from './MuiAutocomplete';
import MuiInputAdornment from './MuiInputAdornment';
import MuiAccordionDetails from './MuiAccordionDetails';
import MuiAccordionSummary from './MuiAccordionSummary';
import MuiListItem from './MuiListItem';
import MuiListSubheader from './MuiListSubheader';
import MuiPickersStaticWrapper from './MuiPickersStaticWrapper';
import MuiPickersToolbar from './MuiPickersToolbar';
import MuiPopover from './MuiPopover';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiTabs from './MuiTabs';
import MuiDropzoneArea from './MuiDropzoneArea';
import MuiListItemIcon from './MuiListItemIcon';
import MuiBadge from './MuiBadge';
import MuiPickersCalendar from './MuiPickersCalendar';
import MuiTab from './MuiTab';

const overrides = {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTypography,
  MuiFilledInput,
  MuiFormLabel,
  MuiInputLabel,
  MuiCheckbox,
  MuiRadio,
  MuiSwitch,
  MuiSlider,
  MuiTable,
  MuiTableRow,
  MuiTablePagination,
  MuiTextField,
  MuiDialog,
  MuiDialogTitle,
  MuiDialogContent,
  MuiLink,
  MuiFormControlLabel,
  MuiMenuItem,
  MuiSelect,
  MuiPagination,
  MuiPaginationItem,
  MuiSkeleton,
  MuiPickersPopper,
  MuiPickersArrowSwitcher,
  MuiPickersDesktopDateRangeCalendar,
  MuiPickersDay,
  MuiChip,
  MuiInputBase,
  MuiPickersDateRangeDay,
  MuiAutocomplete,
  MuiAccordion,
  MuiInputAdornment,
  MuiAccordionDetails,
  MuiAccordionSummary,
  MuiListItem,
  MuiListSubheader,
  MuiPickersStaticWrapper,
  MuiPickersToolbar,
  MuiPopover,
  MuiTab,
  MuiTabs,
  MuiDropzoneArea,
  MuiListItemIcon,
  MuiBadge,
  MuiPickersCalendar,
  MuiMenu,
};

export default overrides;
