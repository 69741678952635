import palette from '../palette';

export default {
  colorSecondary: {
    color: palette.grey['300'],
    '&:hover $thumb': {
      border: `2px solid ${palette.grey['400']}`,
    },
    '&$checked': {
      color: palette.secondary.main,
    },
    '&$checked + $track': {
      backgroundColor: palette.secondary.main,
      opacity: 1,
    },
    '&$checked $thumb': {
      borderColor: palette.secondary.light,
      opacity: 1,
    },
    '&$disabled': {
      color: palette.grey['200'],
    },
    '&$disabled + $track': {
      backgroundColor: palette.grey['200'],
      opacity: 1,
    },
    '&$disabled $thumb': {
      border: `2px solid ${palette.grey['200']}`,
      width: '20px',
      height: '20px',
    },
  },
  thumb: {
    border: `2px solid ${palette.grey['300']}`,
    backgroundColor: palette.white,
    width: '20px',
    height: '20px',
    boxShadow: 'none',
  },
  track: {
    backgroundColor: palette.grey['300'],
    opacity: 1,
  },
};
