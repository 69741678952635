import palette from '../palette';

export default {
  contained: {
    background: palette.buttons.blueMain,
    color: 'white',
    borderRadius: '8px',
    boxShadow: 'none',
    padding: '9px 16px',
    '&:hover': {
      background: palette.buttons.blueDark,
      boxShadow: 'none',
    },
    '&:active': {
      background: palette.buttons.blueLight,
      boxShadow: 'none',
    },
    '&$disabled': {
      background: 'none',
      backgroundColor: palette.grey['300'],
      color: palette.text.disabled,
      boxShadow: 'none',
      filter: 'none',
    },
  },
  containedSizeLarge: {
    fontSize: '0.938rem',
    lineHeight: 1.2,
    fontWeight: 500,
    letterSpacing: '0.029em',
    padding: '12px 22px',
    borderRadius: '8px',
  },
  containedSizeSmall: {
    fontSize: '0.813rem',
    lineHeight: 1.2,
    fontWeight: 500,
    letterSpacing: '0.029em',
    padding: '7px 10px',
    borderRadius: '8px',
  },
  outlined: {
    border: `1px solid ${palette.grey['300']}`,
    color: palette.text.primary,
    background: palette.background.paper,
    borderRadius: '8px',
    padding: '9px 16px',
    filter: 'drop-shadow(0px 3px 4px rgba(233, 242, 242, 0.32))',
    '& $startIcon': {
      transition: '250ms',
      color: palette.primary.main,
    },
    '&:hover': {
      border: `1px solid ${palette.primary.light}`,
      background: palette.background.paper,
      color: palette.primary.main,
      filter: 'drop-shadow(0px 3px 4px rgba(0, 146, 192, 0.12))',
      '& $startIcon': {
        color: palette.primary.main,
      },
    },
    '&:active': {
      border: `1px solid ${palette.primary.light}`,
      background: palette.background.paper,
      color: palette.primary.main,
      filter: 'drop-shadow(0px 3px 4px rgba(0, 146, 192, 0.12))',
    },
    '&$disabled': {
      border: `1px solid ${palette.grey['300']}`,
      color: palette.text.disabled,
      boxShadow: 'none',
      filter: 'none',
      '& $startIcon': {
        color: palette.grey['400'],
      },
    },
  },
  outlinedSizeLarge: {
    fontSize: '0.938rem',
    lineHeight: '1.125em',
    fontWeight: 500,
    letterSpacing: '0.029em',
    padding: '12px 22px',
    borderRadius: '8px',
  },
  outlinedSizeSmall: {
    fontSize: '0.875rem',
    lineHeight: '1em',
    fontWeight: 500,
    letterSpacing: '0.006em',
    padding: '7px 10px',
    borderRadius: '8px',
  },
  text: {
    color: palette.text.primary,
    '&:hover': {
      color: palette.primary.main,
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    '&:active': {
      color: palette.primary.light,
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
    '&$disabled': {
      color: palette.text.disabled,
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  },
  textSizeLarge: {
    fontSize: '0.938rem',
    lineHeight: 1.2,
    fontWeight: 500,
    letterSpacing: '0.029em',
  },
  textSizeSmall: {
    fontSize: '0.813rem',
    lineHeight: 1.2,
    fontWeight: 500,
    letterSpacing: '0.029em',
  },
};

