import { useQuery } from '@apollo/client';
import { useBackendUserState } from 'contexts/UserContext';
import userRoles from 'apps/account/constants/userRoles';
import { PRECEPTOR_WIZARD, STUDENT_WIZARD } from 'apps/wizard/graphql/queries';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import paths from '../../../paths';

const useWizardDecisionAndRedirect = () => {
  const history = useHistory();
  const location = useLocation();

  const { state: { user: backendUser } } = useBackendUserState();
  const role = backendUser?.lastUsedRole?.key;

  const { data: { studentWizard } = {}, loading: studentLoading } = useQuery(
    STUDENT_WIZARD, { skip: !role || role === userRoles.preceptor },
  );
  const { data: { preceptorWizard } = {}, loading: preceptorLoading } = useQuery(
    PRECEPTOR_WIZARD, { skip: !role || role === userRoles.student },
  );

  const wizard = {
    wizard: studentWizard || preceptorWizard,
    wizardLoading: studentLoading || preceptorLoading,
  };

  let wizardLink;

  const wizardNotCompleted = !backendUser?.wizardState?.isWizardCompleted;
  const lastCompletedStepSlug = backendUser?.wizardState?.lastWizardStep;

  if (wizard?.wizard && wizardNotCompleted) {
    const firstStepSlug = wizard?.wizard?.steps[0]?.slug;
    if (lastCompletedStepSlug) {
      wizardLink = paths.wizardPaths.wizardStart.reverse({ slug: lastCompletedStepSlug });
    } else if (firstStepSlug) {
      wizardLink = paths.wizardPaths.wizardStart.reverse({ slug: firstStepSlug });
    } else {
      wizardLink = paths.wizardPaths.welcome.reverse();
    }
  }
  const stepSlugs = wizard?.wizard?.steps.map(step => step.slug);

  useEffect(() => {
    if (wizardNotCompleted && wizardLink) {
      const wizardPageOpen = location.pathname.startsWith('/profile/');

      const notCompletedStepOpen = stepSlugs?.indexOf(lastCompletedStepSlug) + 1
        < stepSlugs?.indexOf(location.pathname?.split('/').slice(-2, -1)[0]);

      if (!wizardPageOpen || notCompletedStepOpen) {
        history.push(wizardLink);
      }
    }
  }, [wizardNotCompleted, wizardLink, lastCompletedStepSlug, stepSlugs, history, location.pathname]);

  return {
    ...wizard,
    wizardLink,
  };
};

export default useWizardDecisionAndRedirect;
