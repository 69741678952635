import palette from '../palette';

export default {
  root: {
    '&$selected': {
      backgroundColor: palette.grey[300],
      '&:hover': {
        backgroundColor: palette.grey[100],
      },
    },
  },
  button: {
    '&:hover': {
      backgroundColor: palette.grey[100],
    },
  },
};
