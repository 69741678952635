import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import paths from 'paths';
import Loadable from 'shared/components/Loadable';
import LayoutHeaderFooter from 'layouts/LayoutHeaderFooter';
import withTitle from 'shared/HOCs/withTitle';
import AuthGuard from 'shared/components/AuthGuard';

const SettingsPage = withTitle('Settings Page')(Loadable(lazy(() => import('apps/settings/pages/Settings'))));
// -----------------------|| COMMON ROUTING ||-----------------------//

const Routes = () => {
  const location = useLocation();

  return (
    <Route
      path={[
        paths.settingsPaths.changePassword.pattern,
      ]}
      exact
    >
      <LayoutHeaderFooter>
        <Switch location={location} key={location.pathname}>
          <AuthGuard>
            <Route
              path={[
                paths.settingsPaths.changePassword.pattern]}
              exact
              component={SettingsPage}
            />
          </AuthGuard>
        </Switch>
      </LayoutHeaderFooter>
    </Route>
  );
};

export default Routes;
