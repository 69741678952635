import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import typography from '../../../theme/typography';

const patientsNumberBlockStyles = makeStyles(theme => createStyles({
  icon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1),
  },
  fireIcon: {
    marginRight: 11,
    marginLeft: theme.spacing(0.5),
  },
  numberPatients: {
    color: theme.palette.warning.main,
  },
  numberText: {
    ...typography.subtitle3,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1),
  },
}));

export default patientsNumberBlockStyles;
