import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const userTabsStyles = makeStyles(() => createStyles({
  tab: {
    width: '7rem !important',
    minWidth: '7rem !important',
  },
  invisibleTab: {
    width: '0 !important',
    minWidth: '0 !important',
  },
  tabs: {
    '& .MuiTabs-flexContainer': {
      minHeight: '75px',
    },
    '& .MuiTabs-indicator': {
      maxWidth: '7rem',
      width: '7rem',
    },
  },
}));

export default userTabsStyles;
