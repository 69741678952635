export default {
  preceptorNeeded: 'preceptor_needed',
  firstPaymentFailed: 'first_payment_failed',
  preceptorRequested: 'preceptor_requested',
  requestRejected: 'request_rejected',
  requestAccepted: 'request_accepted',
  replacementApprovalRequested: 'replacement_approval_requested',
  secondPaymentFailed: 'second_payment_failed',
  agreementRequested: 'agreement_requested',
  agreementRejected: 'agreement_rejected',
  agreementAccepted: 'agreement_accepted',
  thirdPaymentFailed: 'third_payment_failed',
  schoolApprovalRequested: 'school_approval_requested',
  affAgreementRequested: 'aff_agreement_requested',
  rotationStartRequested: 'rotation_start_requested',
  pendingRotationCompletion: 'pending_rotation_completion',
  canceled: 'canceled',
};
