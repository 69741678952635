import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import paths from 'paths';
import Loadable from 'shared/components/Loadable';
import LayoutHeaderFooter from 'layouts/LayoutHeaderFooter';
import withTitle from 'shared/HOCs/withTitle';
import AuthGuard from 'shared/components/AuthGuard';

// const FinancesPreceptorPage =
//   withTitle('Finances Page')(Loadable(lazy(() => import('apps/preceptor/pages/Finances'))));
const AvailabilityPage = withTitle('Availability Page')(Loadable(lazy(() => import('apps/preceptor/pages/Availability'))));
const RotationSummaryUpcomingPage = withTitle('Rotation Summary Upcoming Page')(Loadable(lazy(() => import('apps/preceptor/pages/RotationSummaryUpcoming'))));
const ProfileForPreceptorPage = withTitle('Profile For Preceptor')(Loadable(lazy(() => import('apps/preceptor/pages/Profile'))));

// -----------------------|| COMMON ROUTING ||-----------------------//

const Routes = () => {
  const location = useLocation();

  return (
    <Route
      path={[
        paths.preceptorPaths.listView.pattern,
        paths.preceptorPaths.calendarView.pattern,
        paths.preceptorPaths.honorarium.pattern,
        paths.preceptorPaths.payouts.pattern,
        paths.preceptorPaths.student.pattern,
        paths.preceptorPaths.paperwork.pattern,
        paths.preceptorPaths.payments.pattern,
        paths.preceptorPaths.profileForPreceptor.pattern,
      ]}
      exact
    >
      <LayoutHeaderFooter>
        <Switch location={location} key={location.pathname}>
          <AuthGuard>
            {/* <Route
              path={[
                paths.preceptorPaths.honorarium.pattern,
                paths.preceptorPaths.payouts.pattern]}
              exact
              component={FinancesPreceptorPage}
            /> */}
            <Route
              path={[
                paths.preceptorPaths.listView.pattern,
                paths.preceptorPaths.calendarView.pattern]}
              exact
              component={AvailabilityPage}
            />
            <Route
              path={[
                paths.preceptorPaths.student.pattern,
                paths.preceptorPaths.paperwork.pattern,
                paths.preceptorPaths.payments.pattern]}
              exact
              component={RotationSummaryUpcomingPage}
            />
            <Route
              path={
                  paths.preceptorPaths.profileForPreceptor.pattern
              }
              exact
              component={ProfileForPreceptorPage}
            />
          </AuthGuard>
        </Switch>
      </LayoutHeaderFooter>
    </Route>
  );
};

export default Routes;
