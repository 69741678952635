import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DialogTitle from '../../../../shared/components/DialogTitle';

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Email verification Student
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Verify Your Email
        </DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <Typography variant="subtitle2" color="textSecondary">
              Thanks for joining PreceptorLink, John!
            </Typography>
          </Box>
          <Box mb={2} maxWidth="430px">
            <Typography variant="subtitle2">
              We have sent you an email with a link to confirm your account.
              Click on the link and continue with your account setup.
            </Typography>
          </Box>
          <Box mb={3}>
            <Button
              variant="contained"
              size="large"
              fullWidth
            >
              Resend Verification Link
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
