import palette from '../palette';

export default {
  positionEnd: {
    color: palette.icon,
  },
  positionStart: {
    color: palette.icon,
  },
};
