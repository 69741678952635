import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { DATA } from 'shared/queries/utils/queries';

const DataContext = createContext({});

/**
 * @param children
 * @returns {JSX.Element}
 *
 * Stores PreceptorLink backend data
 */
export const DataProvider = ({ children }) => {
  const [state, setState] = useState(undefined);

  const { data: { data } = {} } = useQuery(DATA, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setState(data);
  }, [data]);

  return (
    <DataContext.Provider value={{ state }}>
      {children}
    </DataContext.Provider>
  );
};

DataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useBackendDataState = () => useContext(DataContext);
