import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import SearchTagHidden from 'shared/components/SearchTagHidden';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import specialtyBlockStyles from './styles';

const SpecialtyBlock = ({ credentialsString, specialty, limit }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const classes = specialtyBlockStyles();

  return (
    <Box className={classes.block}>
      <Typography variant={isMobile ? 'body1' : 'h6'} className={classes.text}>
        {credentialsString}
      </Typography>
      <SearchTagHidden specialty={specialty} limit={limit} />
    </Box>
  );
};

SpecialtyBlock.defaultProps = {
  specialty: [],
  credentialsString: '',
  limit: undefined,
};

SpecialtyBlock.propTypes = {
  specialty: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })),
  credentialsString: PropTypes.string,
  limit: PropTypes.number,
};

export default SpecialtyBlock;
