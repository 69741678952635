import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, Divider, Typography } from '@material-ui/core';
import { ROTATION_CONSTANTS } from 'shared/queries/rotation/queries';
import stepsBlockStyles from './styles';

const StepsBlock = () => {
  const classes = stepsBlockStyles();

  const {
    data: {
      rotationConstants: {
        customSearchPaymentOne = 150,
        // customSearchPaymentTwo = 850,
      } = {},
    } = {},
  } = useQuery(ROTATION_CONSTANTS);

  return (
    <Box className={classes.block}>
      <Box display="flex">
        <Box>
          <Box className={classes.circle} mr={3}>
            <Typography variant="h6" className={classes.number}>
              1
            </Typography>
          </Box>
          <Box mt={1} ml={3} className={classes.blockDivider}>
            <Divider orientation="vertical" flexItem className={classes.divider} />
          </Box>
        </Box>
        <Box>
          <Typography variant="body2" className={classes.text}>
            Search Fee Payment
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            To start the process, you will pay $
            {customSearchPaymentOne}
            {' '}
            non-refundable Search Fee, which will go towards your total cost. Should things
            proceed, we will take $150 off your rotation fee to credit you. Although there is no
            guarantee we will be able to place you, our expert team will carefully look for your
            specific needs. Our business reputation is built on success, so please know we take
            your search seriously!
          </Typography>
        </Box>
      </Box>
      <Box display="flex" my={2}>
        <Box>
          <Box className={classes.circle} mr={3}>
            <Typography variant="h6" className={classes.number}>
              2
            </Typography>
          </Box>
          <Box mt={1} ml={3} flexItem className={classes.blockDivider}>
            <Divider orientation="vertical" className={classes.divider} />
          </Box>
        </Box>
        <Box>
          <Typography variant="body2" className={classes.text}>
            Placement Confirmation
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            Once a potential preceptor is found, the preceptor’s profile will be added to
            your Favorites in the Custom Search Rotation you created. You can then view and
            select the preceptor who best suits your needs.
          </Typography>
        </Box>
      </Box>
      <Box display="flex">
        <Box>
          <Box className={classes.circle} mr={3}>
            <Typography variant="h6" className={classes.number}>
              3
            </Typography>
          </Box>
          <Box mt={1} ml={3} className={classes.blockDivider}>
            <Divider orientation="vertical" flexItem className={classes.divider} />
          </Box>
        </Box>
        <Box>
          <Typography variant="body2" className={classes.text}>
            Securing a Preceptor
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            Once you select a preceptor, we will reconfirm the preceptor’s availability. We will
            then deduct $150 off the total fees, and you will select the preceptor and pay the
            deposit (25% of the total fee). The preceptor will review your profile, accept your
            request, and a charge of 50% of the total fees will be taken. Any Preceptor Agreements
            can be uploaded to your profile, and we will obtain signatures from the preceptor.
            After signatures are obtained, if needed, the final payment of 25% will be charged.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default StepsBlock;
