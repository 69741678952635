import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import { useSigninCheck } from 'reactfire';
import clsx from 'clsx';
import publicHeaderStyles from './styles';
import PublicFooter from '../../shared/components/PublicFooter';
import EmailVerificationBanner from '../../shared/components/EmailVerificationBanner';
import { useBackendUserState } from '../../contexts/UserContext';

const PublicLayout = ({ children }) => {
  const classes = publicHeaderStyles();
  const { data: signInCheckResult } = useSigninCheck();
  const { state: { user: backendUser } = {} } = useBackendUserState();
  const emailVerified = backendUser?.emailVerified;
  const isUserAuthenticated = signInCheckResult.signedIn;

  const showEmailBanner = isUserAuthenticated && backendUser?.wizardState?.isWizardCompleted && emailVerified === false;

  return (
    <>
      {showEmailBanner && <EmailVerificationBanner top={0} />}
      <Box className={clsx(classes.root, {
        [classes.withEmailVerificationBanner]: showEmailBanner,
      })}
      >
        <Box
          className={classes.content}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
        >
          {children}
        </Box>
        <Box>
          <Divider />
          <PublicFooter />
        </Box>
      </Box>
    </>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PublicLayout;
