import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import typography from '../../../../../theme/typography';

const blockPreceptorReservationMobileStyles = makeStyles(theme => createStyles({
  root: {
    width: '100%',
    background: theme.palette.background.default,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    boxShadow: '0px -6px 10px rgba(77, 160, 186, 0.16), 0px -1px 0px rgba(0, 146, 192, 0.2);',
  },
  text: {
    ...typography.subtitle3,
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },
  iconCalendar: {
    color: theme.palette.primary.dark,
  },
  blockCalendar: {
    background: theme.palette.colors.lightBlue,
    borderRadius: 10,
    padding: 6,
    marginRight: theme.spacing(1),
    display: 'inline-flex',
  },
  date: {
    color: theme.palette.primary.dark,
  },
  hours: {
    color: theme.palette.text.hint,
  },
  iconDollar: {
    color: theme.palette.secondary.main,
    borderRadius: 10,
  },
  blockDollar: {
    background: theme.palette.colors.powderyGreen,
    borderRadius: 10,
    padding: 6,
    marginRight: theme.spacing(1),
    display: 'inline-flex',
  },
}));

export default blockPreceptorReservationMobileStyles;
