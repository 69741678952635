import typography from '../typography';
import palette from '../palette';

export default {
  option: {
    ...typography.subtitle3,
    '&:hover': {
      backgroundColor: palette.grey[100],
    },
    '&[aria-selected="true"]': {
      backgroundColor: palette.grey[300],
    },
    '&[data-focus="true"]': {
      backgroundColor: palette.grey[300],
    },
  },
  inputRoot: {
    '&[class*="MuiFilledInput-root"]': {
      paddingTop: '9px',
      paddingBottom: '3px',
    },
    fontWeight: 400,
    fontSize: '0.875rem',
    letterSpacing: '0.011em',
    lineHeight: 1.43,
    borderRadius: 8,
    color: palette.text.secondary,
  },
  popupIcon: {
    '&[class*="MuiSvgIcon-root"]': {
      fill: palette.icon,
    },
  },
};
