import { gql } from '@apollo/client';

export const STUDENT_WIZARD = gql`
  query studentWizard {
    studentWizard {
      welcomeMessage
      steps {
        uuid
        slug
        label
        index
        icon {
          key
          value
        }
      }
    }
  }
`;

export const PRECEPTOR_WIZARD = gql`
  query preceptorWizard {
    preceptorWizard {
      welcomeMessage
      steps {
        uuid
        slug
        label
        index
        icon {
          key
          value
        }
      }
    }
  }
`;

export const WIZARD_STEP = gql`
  query wizardStep($slug: String) {
    step(slug: $slug) {
      uuid
      label
      slug
      icon {
        key
        value
      }
      index
      prevStepSlug
      nextStepSlug
      kind
      faq
      fieldGroups {
        uuid
        label
        helpText
        fields {
          order
          uuid
          label
          required
          helpText
          options {
            key
            value
          }
          field {
            name
            options {
              key
              value
              group
            }
            datatype {
              key
              value
            }
          }
        }
      }
      submittedData {
        submittedData
      }
      savedData
    }
  }
`;

export const WIZARD_ATTACHMENT_FILENAME = gql`
  query wizardAttachmentFilename($stepSlug: String!, $fieldName: String!) {
    wizardAttachmentFilename(stepSlug: $stepSlug, fieldName: $fieldName) {
      filename
    }
  }
`;
