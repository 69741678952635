import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import contactPlaceholder from '../../../../assets/icons/contactPlaceholder.svg';
import ActionMenu from './partials/ActionMenu';

import tableStyles from './styles';

function createData(
  id,
  name,
  phone,
  city,
  owners,
  communication,
  date,
  status,
) {
  return {
    id, name, phone, city, owners, communication, date, status,
  };
}

const Owners = ({ img, name }) => (
  <Box display="flex" alignItems="center">
    <Box display="flex" alignItems="center" mr={1}>
      <img src={img} alt={name} />
    </Box>
    {name}
  </Box>
);

Owners.propTypes = {
  img: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const rows = [
  createData('41542', 'Belinda Brown1', '(486) 465-6566', 'Greensboro, NC', <Owners img={contactPlaceholder} name="Lynn McComas" />, '12/22/20', '10/11/20', 'Active'),
  createData('41542', 'Belinda Brown2', '(486) 465-6566', 'Greensboro, NC', <Owners img={contactPlaceholder} name="Lynn McComas" />, '12/22/20', '10/11/20', 'Active'),
  createData('41542', 'Belinda Brown3', '(486) 465-6566', 'Greensboro, NC', <Owners img={contactPlaceholder} name="Lynn McComas" />, '12/22/20', '10/11/20', 'Active'),
  createData('41542', 'Belinda Brown4', '(486) 465-6566', 'Greensboro, NC', <Owners img={contactPlaceholder} name="Lynn McComas" />, '12/22/20', '10/11/20', 'Active'),
  createData('41542', 'Belinda Brown5', '(486) 465-6566', 'Greensboro, NC', <Owners img={contactPlaceholder} name="Lynn McComas" />, '12/22/20', '10/11/20', 'Active'),
  createData('41542', 'Belinda Brown6', '(486) 465-6566', 'Greensboro, NC', <Owners img={contactPlaceholder} name="Lynn McComas" />, '12/22/20', '10/11/20', 'Active'),
  createData('41542', 'Belinda Brown7', '(486) 465-6566', 'Greensboro, NC', <Owners img={contactPlaceholder} name="Lynn McComas" />, '12/22/20', '10/11/20', 'Active'),
  createData('41542', 'Belinda Brown8', '(486) 465-6566', 'Greensboro, NC', <Owners img={contactPlaceholder} name="Lynn McComas" />, '12/22/20', '10/11/20', 'Active'),
  createData('41542', 'Belinda Brown9', '(486) 465-6566', 'Greensboro, NC', <Owners img={contactPlaceholder} name="Lynn McComas" />, '12/22/20', '10/11/20', 'Active'),
  createData('41542', 'Belinda Brown10', '(486) 465-6566', 'Greensboro, NC', <Owners img={contactPlaceholder} name="Lynn McComas" />, '12/22/20', '10/11/20', 'Active'),
  createData('41542', 'Belinda Brown11', '(486) 465-6566', 'Greensboro, NC', <Owners img={contactPlaceholder} name="Lynn McComas" />, '12/22/20', '10/11/20', 'Active'),
  createData('41542', 'Belinda Brown12', '(486) 465-6566', 'Greensboro, NC', <Owners img={contactPlaceholder} name="Lynn McComas" />, '12/22/20', '10/11/20', 'Active'),
  createData('41542', 'Belinda Brown13', '(486) 465-6566', 'Greensboro, NC', <Owners img={contactPlaceholder} name="Lynn McComas" />, '12/22/20', '10/11/20', 'Active'),
];

const headCells = [
  {
    id: 'id', numeric: false, disablePadding: true, label: 'ID',
  },
  {
    id: 'name', numeric: false, disablePadding: false, label: 'Name',
  },
  {
    id: 'phone', numeric: false, disablePadding: false, label: 'Phone',
  },
  {
    id: 'city', numeric: false, disablePadding: false, label: 'City, State',
  },
  {
    id: 'owners', numeric: false, disablePadding: false, label: 'Owners',
  },
  {
    id: 'communication',
    numeric: false,
    disablePadding: false,
    label: 'Last\n'
      + 'Communication',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Date\n'
      + 'Created',
  },
  {
    id: 'status', numeric: false, disablePadding: false, label: 'Status',
  },
];

function EnhancedTableHead(props) {
  const {
    // eslint-disable-next-line react/prop-types,no-unused-vars
    onSelectAllClick, numSelected, rowCount,
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

const EnhancedTable = () => {
  const classes = tableStyles();
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  return (
    <>
      <Typography variant="h5" align="center" gutterBottom>
        Table
      </Typography>
      <div className={classes.root}>
        {/* <Paper className={classes.paper}> */}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        role="checkbox"
                        onClick={event => handleClick(event, row.name)}
                      >
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell id={labelId}>
                        {row.id}
                      </TableCell>
                      <TableCell id={labelId}>
                        {row.name}
                      </TableCell>
                      <TableCell id={labelId}>
                        {row.phone}
                      </TableCell>
                      <TableCell align="left">{row.city}</TableCell>
                      <TableCell align="left">{row.owners}</TableCell>
                      <TableCell align="left">{row.communication}</TableCell>
                      <TableCell align="left">{row.date}</TableCell>
                      <TableCell className={classes.status} align="left">{row.status}</TableCell>
                      <TableCell align="right"><ActionMenu /></TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          SelectProps={{ IconComponent: ExpandMoreIcon }}
        />
        {/* </Paper> */}
      </div>
    </>
  );
};

export default EnhancedTable;
