import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { Link as ScrollLink } from 'react-scroll';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const HowItWork = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box>
      <Box>
        <Typography variant={isMobile ? 'h5' : 'h3'} color="secondary">
          How It Works
        </Typography>
      </Box>
      <Typography variant={isMobile ? 'subtitle2' : 'subtitle1'} color="textSecondary">
        We have thousands of preceptors, and not all of them have been added to the app yet.
        Additionally, as a seasoned NP, Lynn McComas, DNP, ANP-C, our NP-owner, has tens of
        thousands of colleagues. Our resourceful team are specialists at searching for your needs!
        We will use our contacts and experience to find you what you need if it is not in our
        Quick Search.
      </Typography>
      <Box mt={3} mb={1}>
        <ScrollLink
          to="form"
          smooth
          duration={500}
          offset={-100}
        >
          <Button
            variant="contained"
            size="large"
          >
            Request Custom Search
          </Button>
        </ScrollLink>
      </Box>
    </Box>
  );
};

export default HowItWork;
