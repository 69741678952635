import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import EventNoteIcon from '@material-ui/icons/EventNote';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Button from '@material-ui/core/Button';
import blockPreceptorReservationMobileStyles from './styles';

const BlockPreceptorReservationMobile = (
) => {
  const classes = blockPreceptorReservationMobileStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.text}>
        rotation summary
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        mt={1.5}
        mb={1}
      >
        <Box className={classes.blockCalendar}>
          <EventNoteIcon className={classes.iconCalendar} fontSize="small" />
        </Box>
        <Typography variant="body1" className={classes.date}>
          Jun 2, 2021 – Jul 5, 2021
        </Typography>
        <Typography variant="body1" className={classes.hours}>
            &nbsp;(160 hrs)
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        mb={2}
      >
        <Box className={classes.blockDollar}>
          <MonetizationOnIcon className={classes.iconDollar} fontSize="small" />
        </Box>
        <Typography variant="body2" color="secondary">
          $2500
        </Typography>
      </Box>
      <Button
        variant="contained"
        size="large"
        fullWidth
      >
        REVIEW & RESERVE
      </Button>
    </Box>
  );
};

export default BlockPreceptorReservationMobile;
