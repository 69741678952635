import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const paymentBlockStyles = makeStyles(theme => createStyles({
  block: {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 20,
    padding: theme.spacing(3),
  },
}));

export default paymentBlockStyles;
