import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import queryString from 'query-string';

const useQueryString = () => {
  const { search } = useLocation();
  const history = useHistory();

  const [prevSearch, setPrevSearch] = useState('');
  const [urlParams, setUrlParams] = useState(queryString.parse(search, { arrayFormat: 'comma' }));

  const setQueryString = params => {
    const cleanedParams = Object.entries(params).reduce((acc, [k, v]) => {
      if (v !== '') {
        acc[k] = v;
      }
      return acc;
    }, {});

    const qString = queryString.stringify(cleanedParams, { arrayFormat: 'comma' });
    history.push({ search: qString });
  };

  useEffect(() => {
    if (search !== prevSearch) {
      setPrevSearch(search);
      setUrlParams(queryString.parse(search, { arrayFormat: 'comma' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return {
    urlParams,
    setQueryString,
  };
};

export default useQueryString;
