import palette from '../palette';

export default {
  root: {
    height: 'unset',
  },
  select: {
    '&:focus': {
      backgroundColor: 'none',
      borderRadius: '8px',
    },
  },
  filled: {
    backgroundColor: 'none',
  },
  selectMenu: {
    height: '1.1876em',
  },
  icon: {
    color: palette.icon,
  },
};
