import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import paths from 'paths';
import Loadable from 'shared/components/Loadable';
import LayoutHeaderFooter from 'layouts/LayoutHeaderFooter';
import withTitle from 'shared/HOCs/withTitle';

const SignInPage = withTitle('Sign In')(Loadable(lazy(() => import('apps/account/pages/SignIn'))));
const SignUpPage = withTitle('Sign In')(Loadable(lazy(() => import('apps/account/pages/SignUp'))));

// -----------------------|| Accounts ROUTING ||-----------------------//

const Routes = () => {
  const location = useLocation();

  return (
    <Route
      path={[
        paths.accountPaths.signIn.pattern,
        paths.accountPaths.signUp.pattern,
      ]}
      exact
    >
      <LayoutHeaderFooter>
        <Switch location={location} key={location.pathname}>
          <Route
            path={paths.accountPaths.signIn.pattern}
            exact
            component={SignInPage}
          />
          <Route
            path={paths.accountPaths.signUp.pattern}
            exact
            component={SignUpPage}
          />
        </Switch>
      </LayoutHeaderFooter>
    </Route>
  );
};

export default Routes;
