import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import blockSearchRecommendationsMiStyles from './styles';
import Recommendations from '../../../../shared/components/BlockSearchRecommendations/partials/Recommendations';

const BlockSearchRecommendationsMi = () => {
  const classes = blockSearchRecommendationsMiStyles();
  return (
    <>
      <Box
        className={classes.block}
      >
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Typography variant="h6">
            Search Recommendations
          </Typography>
          <CloseIcon className={classes.iconClose} />
        </Box>
        <Typography className={classes.text}>
          Add 10 mi to the radius user selected & suggest new location
          as for the recommendation
        </Typography>
        <Recommendations preceptorCount="30 Preceptors in Washington" rating={4.8} cost={1500} />
        <Box className={classes.button}>
          <Button
            variant="outlined"
            size="medium"
            fullWidth
          >
            View More
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default BlockSearchRecommendationsMi;
