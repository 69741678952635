import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import typography from 'theme/typography';

const expandedReservationBlockStyles = makeStyles(theme => createStyles({
  root: {
    background: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 20,
    padding: theme.spacing(3),
    margin: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
  },
  icon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1),
  },
  iconDollar: {
    color: theme.palette.secondary.main,
    borderRadius: 10,
  },
  blockDollar: {
    background: theme.palette.colors.powderyGreen,
    borderRadius: 10,
    padding: 6,
    marginRight: theme.spacing(2),
    display: 'flex',
  },
  textHint: {
    color: theme.palette.text.hint,
  },
  caption: {
    ...typography.caption,
    color: theme.palette.text.secondary,
  },
  item: {
    '&.MuiListItem-button:hover': {
      backgroundColor: theme.palette.colors.lightGreenStatus,
      color: theme.palette.secondary.main,
      '& $caption': {
        color: `${theme.palette.secondary.main} !important`,
      },
    },
  },
}));

export default expandedReservationBlockStyles;
