import palette from './palette';

const typography = {
  h1: {
    color: palette.text.primary,
    fontWeight: 300,
    fontSize: '6rem',
    letterSpacing: '-0.01562em',
    lineHeight: 1.16,
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 300,
    fontSize: '3.75rem',
    letterSpacing: '-0.00833em',
    lineHeight: 1.2,
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 300,
    fontSize: '3rem',
    letterSpacing: 0,
    lineHeight: 1.16,
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 300,
    fontSize: '2.125rem',
    letterSpacing: '0.00735em',
    lineHeight: 1.23,
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '1.5rem',
    letterSpacing: 0,
    lineHeight: 1.33,
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '1.25rem',
    letterSpacing: '0.0075em',
    lineHeight: 1.6,
  },
  subtitle1: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '1rem',
    letterSpacing: '0.00938em',
    lineHeight: 1.75,
  },
  subtitle2: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '0.875rem',
    letterSpacing: '0.011em',
    lineHeight: 1.43,
  },
  subtitle3: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '0.813rem',
    letterSpacing: '0.025em',
    lineHeight: 1.66,
    maxWidth: '95%',
  },
  body1: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '1rem',
    letterSpacing: '0.00938em',
    lineHeight: 1.5,
  },
  body2: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '1rem',
    letterSpacing: '0.01071em',
    lineHeight: 1.5,
  },
  button: {
    fontSize: '0.875rem',
    lineHeight: 1.3,
    fontWeight: 500,
    letterSpacing: '0.028em',
    textTransform: 'none',
  },
  caption: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '0.75rem',
    letterSpacing: '0.03333em',
    lineHeight: 1,
  },
  overline: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '0.625rem',
    letterSpacing: '0.015em',
    lineHeight: 1.2,
    textTransform: 'uppercase',
  },
};

export default typography;
