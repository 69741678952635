import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Divider, Paper } from '@material-ui/core';
import listOfPreceptorsStyles from './styles';
import 'react-custom-scroll/dist/customScroll.css';
import PreceptorCard from '../../PreceptorCard';

const ListOfPreceptors = () => {
  const classes = listOfPreceptorsStyles();

  return (
    <Paper className={classes.paper} elevation={0}>
      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" padding="24px 14px 24px 24px">
          <Typography variant="h6">
            List of preceptors
          </Typography>
          <CloseIcon className={classes.iconClose} />
        </Box>
        <Divider />
        <PreceptorCard
          accountUUID="ftytu"
          accountUID="hghhhhhh"
          generalLocation="velllll, hhhhhhhhh"
          credentials={[{ key: 5, value: 'credentials' }]}
          typeOfPractice={[{ key: 5, value: 'type' }]}
          rate={4}
          specialty={[{ key: 5, value: 'specialty' }]}
          averageNumberOfPatientsPerDay={11}
          small
        />
        <Divider />
        <PreceptorCard small />
        <Divider />
        <PreceptorCard small />
        <Divider />
        <PreceptorCard small />
        <Divider />
        <PreceptorCard small />
        <Divider />
        <PreceptorCard small />
      </Box>
    </Paper>
  );
};

export default ListOfPreceptors;
