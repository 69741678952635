import React from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import paths from 'paths';
import conflictingRotationsStyles from './styles';

const ConflictingRotations = ({ conflictingRotations }) => {
  const classes = conflictingRotationsStyles();

  const linkToRotation = conflictingRotation => {
    const preferencesJSON = JSON.parse(conflictingRotation.preferences || '{}');

    if (conflictingRotation.currentRequest) {
      return paths.rotationPaths.rotationPreceptor.reverse({ uuid: conflictingRotation.uuid });
    }
    if (preferencesJSON.locations) {
      return paths.rotationPaths.rotationAllPreceptors.reverse({ uuid: conflictingRotation.uuid });
    }
    return paths.rotationPaths.rotationPreferences.reverse({ uuid: conflictingRotation.uuid });
  };

  return (
    <Box ml={1.5}>
      {conflictingRotations?.totalCount > 0 && (
        <Typography className={classes.conflictingRotation}>
          You already have rotation(s) created for these dates:
          <Typography variant="span" style={{ color: '#0092C0' }}>
            {' ['}
            {conflictingRotations?.results?.map((conflictingRotation, index) => (
              <>
                <Link style={{ fontSize: 12 }} target="_blank" href={linkToRotation(conflictingRotation)}>
                  {conflictingRotation.uid}
                </Link>
                {index < conflictingRotations?.totalCount - 1 && ', '}
              </>
            ))}
            {']'}
          </Typography>
        </Typography>
      )}
    </Box>
  );
};

ConflictingRotations.defaultProps = {
  conflictingRotations: undefined,
};

ConflictingRotations.propTypes = {
  conflictingRotations: PropTypes.shape({
    totalCount: PropTypes.number,
    results: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default ConflictingRotations;
