import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import SignInDialog from 'apps/account/components/SignInDialog';
import { Divider } from '@material-ui/core';
import ListOfPreceptors from 'shared/components/BlockDetails/ListOfPreceptors';
import UserMenu from '../../shared/components/UserMenu';
import TextFields from './partials/text-fields';
import Buttons from './partials/buttons';
import CheckBox from './partials/checkbox';
import RadioButton from './partials/radiobutton';
import Switchs from './partials/switch';
import Sliders from './partials/slider';
import Table from './partials/Table/index';
import DialogWelcome from '../../apps/account/components/DialogWelcome/index';
import DialogVerifyEmailPreceptor from './partials/DialogVerifyEmailPreceptor/index';
import DialogVerifyEmailStudent from './partials/DialogVerifyEmailStudent/index';
import DialogForgotPassword from '../../apps/account/components/ForgotPasswordDialog/index';
import Pagination from '../../shared/components/BlockSearchRecommendations/partials/Pagination';
import BlockSearchRecommendations from '../../shared/components/BlockSearchRecommendations';
import BlockSearchRecommendationsMi from '../../apps/searchPublic/components/BlockSearchReccomendationsMi';
import Chat from '../../apps/searchPublic/components/Chat';
import NotFoundSearch from '../../apps/searchPublic/components/NotFoundSearch';
import SearchRecommendations from '../../apps/searchPublic/components/SearchRecommendations';
import ExpandedReservationBlock
from '../../apps/searchPublic/components/BlockPreceptorReservation/ExpandedReservationBlock';
import BlockPreceptorReservationMobile
from '../../apps/searchPublic/components/BlockPreceptorReservation/BlockPreceptorReservationMobile';

const DesignSystem = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Buttons />
      <TextFields />
      <CheckBox />
      <RadioButton />
      <Switchs />
      <Sliders />
      <Box px={2}>
        <Table />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box bgcolor="#1DBA7F">
          <UserMenu />
        </Box>
        <Box mb={2}>
          <DialogWelcome />
        </Box>
        <DialogVerifyEmailStudent />
        <Box mb={2} mt={2}>
          <DialogVerifyEmailPreceptor />
        </Box>
        <DialogForgotPassword />
        <Box mb={2}>
          <SignInDialog />
        </Box>
        <BlockSearchRecommendations />
        <Box my={2}>
          <BlockSearchRecommendationsMi />
        </Box>
        <Box my={2}>
          <Pagination totalCount={100} itemsPerPage={10} />
        </Box>
      </Box>
      <Chat />
      <NotFoundSearch />
      <Divider />
      <ListOfPreceptors />
      <Divider />
      <SearchRecommendations />
      <Divider />
      <Box my={2}>
        <Grid container>
          <Grid item xs={12} md={3}>
            <ExpandedReservationBlock />
          </Grid>
        </Grid>
      </Box>
      <Grid container>
        <Grid item xs={12} md={3}>
          <BlockPreceptorReservationMobile />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default DesignSystem;
