import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const theme = createMuiTheme({
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
    MuiCheckbox: {
      color: 'primary',
    },
    MuiSelect: {
      IconComponent: ExpandMoreIcon,
    },
    MuiAutocomplete: {
      popupIcon: <ExpandMoreIcon />,
    },
  },
  palette,
  typography,
  overrides,
  spacing: 8,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
});

export default theme;
