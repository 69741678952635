import { Box, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import rotationInfoItemStyles from './styles';

const RotationInfoItem = ({ title, value }) => {
  const classes = rotationInfoItemStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box display="flex" flexDirection="column" mr={2} mt={isMobile ? 2 : 0}>
      <Typography className={classes.label}>
        {title}
      </Typography>
      <Typography variant="subtitle2">
        {value}
      </Typography>
    </Box>
  );
};

RotationInfoItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default RotationInfoItem;
