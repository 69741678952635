import RoutePath from 'shared/utils/namedUrls';

const basePath = '/account/';

export default {
  signIn: new RoutePath(`${basePath}sign-in/`),
  signUp: new RoutePath(`${basePath}sign-up/`),
  user: new RoutePath(`${basePath}user/:id/`),
  customSearch: new RoutePath(`${basePath}custom-search/`),
};
