import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import paths from 'paths';
import Loadable from 'shared/components/Loadable';
import withTitle from 'shared/HOCs/withTitle';
import LayoutHeaderFooter from 'layouts/LayoutHeaderFooter';

const PrivacyPolicyPage = withTitle('Privacy Policy')(Loadable(lazy(() => import('apps/common/pages/PrivacyPolicy'))));
const TermsAndConditionsPage = withTitle('Terms And Conditions')(Loadable(lazy(() => import('apps/common/pages/TermsAndConditions'))));
const RefundPolicyPage = withTitle('Refund Policy')(Loadable(lazy(() => import('apps/common/pages/RefundPolicy'))));

// -----------------------|| COMMON ROUTING ||-----------------------//

const Routes = () => {
  const location = useLocation();

  return (
    <Route
      path={[
        paths.commonPaths.privacyPolicy.pattern,
        paths.commonPaths.termsAndConditions.pattern,
        paths.commonPaths.refundPolicy.pattern,
      ]}
      exact
    >
      <LayoutHeaderFooter>
        <Switch location={location} key={location.pathname}>
          <Route
            path={paths.commonPaths.privacyPolicy.pattern}
            exact
            component={PrivacyPolicyPage}
          />
          <Route
            path={paths.commonPaths.termsAndConditions.pattern}
            component={TermsAndConditionsPage}
          />
          <Route
            path={paths.commonPaths.refundPolicy.pattern}
            component={RefundPolicyPage}
          />
        </Switch>
      </LayoutHeaderFooter>
    </Route>
  );
};

export default Routes;
