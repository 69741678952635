import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Box, Typography, Button, IconButton, Link,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// import ChatIcon from '@material-ui/icons/Chat';
import Cookies from 'js-cookie';
import paths from 'paths';
import chatStyles from './styles';

const Chat = () => {
  const classes = chatStyles();
  const theme = useTheme();
  const [hideChat, setHideChat] = useState(Cookies.get('hideChat'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    Cookies.set('hideChat', true, { expires: 1 });
    setHideChat(true);
  };

  if (hideChat) return null;

  return (
    <Box className={classes.root}>
      <Box className={classes.block}>
        <Box px={isMobile ? 2 : 5}>
          <Typography variant={isMobile ? 'body1' : 'h6'} className={classes.text}>
            Can’t find the Preceptor you are looking for?
          </Typography>
          <Typography className={classes.textChat}>
            If your options are limited, consider moving your dates out to the future.
            It might be worth the wait!
            <br />
            Or chat with one of our team members,
            and we will help you out!
          </Typography>
        </Box>
        <IconButton className={classes.iconClose} onClick={handleClose}>
          <CloseIcon color="primary" />
        </IconButton>
      </Box>
      <Box className={classes.button}>
        <Link className={classes.customSearchLink} target="_blank" href={paths.accountPaths.customSearch.reverse()}>
          <Button style={{ position: 'inherit' }} variant="contained" size="medium">
            Custom Search
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default Chat;
