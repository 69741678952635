import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButtonStyled from '../../../shared/components/BlockSearchRecommendations/partials/IconButtonStyled';
import { ReactComponent as AddLocationIcon } from '../../../assets/icons/addLocation.svg';

const Buttons = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Box mt={2}>
        <Typography variant="h5" align="center" gutterBottom>
          Contained buttons
        </Typography>
      </Box>
      <Grid container justify="center" alignItems="baseline" spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            size="large"
          >
            Large Text
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            size="medium"
          >
            Medium Text
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            size="small"
          >
            Small Text
          </Button>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Typography variant="h5" align="center" gutterBottom>
          Outlined buttons
        </Typography>
        <Grid container justify="center" alignItems="baseline" spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              size="large"
            >
              Large Text
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              size="medium"
            >
              Medium Text
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              size="small"
            >
              Small Text
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box mb={4} mt={4}>
        <Typography variant="h5" align="center" gutterBottom>
          Text buttons
        </Typography>
        <Grid container justify="center" alignItems="baseline" spacing={2}>
          <Grid item>
            <Button
              size="large"
            >
              Large Text
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="medium"
            >
              Medium Text
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
            >
              Small Text
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Typography variant="h5" align="center" gutterBottom>
        Disabled buttons
      </Typography>
      <Grid container justify="center" alignItems="baseline" spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            disabled
            size="medium"
          >
            Disabled contained
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            disabled
            size="medium"
          >
            Disabled outlined
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="medium"
            disabled
          >
            Disabled text button
          </Button>
        </Grid>
      </Grid>

      <Box mt={4}>
        <Typography variant="h5" align="center" gutterBottom>
          Icon buttons
        </Typography>
        <Grid container justify="center" alignItems="baseline" spacing={2}>
          <Grid item>
            <IconButtonStyled>
              <AddLocationIcon />
            </IconButtonStyled>
          </Grid>
          <Grid item>
            <IconButtonStyled disabled>
              <AddLocationIcon />
            </IconButtonStyled>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  </Grid>
);

export default Buttons;
