import palette from '../palette';
import typography from '../typography';

export default {
  caption: {
    ...typography.subtitle3,
    color: palette.text.secondary,
  },
  select: {
    ...typography.subtitle3,
    color: palette.text.primary,
    paddingTop: 0,
  },
  menuItem: {
    ...typography.subtitle3,
  },
  selectIcon: {
    color: palette.icon,
  },
  actions: {
    color: palette.primary.main,
  },
};
