import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSigninCheck } from 'reactfire';
import SignIn from 'apps/account/pages/SignIn';

const RouteWithLayout = ({
  layout: Layout, component: Component, loginRequired, ...rest
}) => {
  const { data: signInCheckResult } = useSigninCheck();

  return (
    <Route
      {...rest}
      render={() => (
        loginRequired && !signInCheckResult.signedIn ? (
          <SignIn />
        ) : (
          <Layout>
            <Component />
          </Layout>
        )
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  layout: PropTypes.elementType.isRequired,
  component: PropTypes.elementType.isRequired,
  loginRequired: PropTypes.bool.isRequired,
};

export default RouteWithLayout;
