import RoutePath from 'shared/utils/namedUrls';

const basePath = '/preceptor/';

export default {
  student: new RoutePath(`${basePath}rotation-summary/:uuid/student/`),
  paperwork: new RoutePath(`${basePath}rotation-summary/:uuid/paperwork/`),
  payments: new RoutePath(`${basePath}rotation-summary/:uuid/payments/`),

  listView: new RoutePath(`${basePath}availability/list-view/`),
  calendarView: new RoutePath(`${basePath}availability/calendar-view/`),

  honorarium: new RoutePath(`${basePath}finances/honorarium/`),
  payouts: new RoutePath(`${basePath}finances/payouts/`),

  profileForPreceptor: new RoutePath(`${basePath}profile/`),
};
