import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => createStyles({
  textSearch: {
    marginRight: 5,
    marginLeft: 5,
  },
  text: {
    marginRight: 5,
  },
  dividerRight: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  dividerLeft: {
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
}));
