import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const notFoundSearchStyles = makeStyles(theme => createStyles({
  block: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
    height: 'calc(100vh - 345px)',
    minHeight: '425px',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
      minHeight: '435px',
    },
  },
  image: {
    maxWidth: '100%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
  textHint: {
    color: theme.palette.text.hint,
  },
  popover: {
    borderRadius: '8px',
    boxShadow: '0px 7px 24px rgba(26, 38, 38, 0.1)',
    border: `1px solid ${theme.palette.grey[400]}`,
    minWidth: 120,
  },
}));

export default notFoundSearchStyles;
