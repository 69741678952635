import RoutePath from 'shared/utils/namedUrls';

const basePath = '/rotations/';

export default {
  rotationReservationPayment: new RoutePath(`${basePath}reservation-payment/:uuid`),

  all: new RoutePath(`${basePath}all/`),
  new: new RoutePath(`${basePath}new/`),
  upcoming: new RoutePath(`${basePath}upcoming/`),
  inProgress: new RoutePath(`${basePath}inProgress/`),
  completed: new RoutePath(`${basePath}completed/`),
  canceled: new RoutePath(`${basePath}canceled/`),
  refunded: new RoutePath(`${basePath}refunded/`),
  tabName: new RoutePath(`${basePath}:tabName/`),

  rotationPreferences: new RoutePath(`${basePath}:uuid/preferences/`),

  rotationAllPreceptors: new RoutePath(`${basePath}:uuid/all-preceptors/`),
  rotationFavorites: new RoutePath(`${basePath}:uuid/favorites/`),
  rotationCanceled: new RoutePath(`${basePath}:uuid/canceled/`),

  rotationPreceptor: new RoutePath(`${basePath}:uuid/in-progress/`),
  rotationPaperwork: new RoutePath(`${basePath}:uuid/paperwork/`),
  rotationPayments: new RoutePath(`${basePath}:uuid/payments/`),

  confirmRotationStart: new RoutePath(`${basePath}:uuid/start/`),
  acceptStudent: new RoutePath(`${basePath}:uuid/accept-request/`),
  rejectStudent: new RoutePath(`${basePath}:uuid/reject-request/`),
};
