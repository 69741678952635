import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import palette from '../../../theme/palette';
import typography from '../../../theme/typography';

const refundPolicyLinkStyles = makeStyles(theme => createStyles({
  link: {
    ...typography.subtitle1,
    color: theme.palette.secondary.main,
    '&:active': {
      color: palette.secondary.dark,
      underline: palette.secondary.dark,
    },
  },
}));

export default refundPolicyLinkStyles;
