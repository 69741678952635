import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

const DialogTitle = ({ children, onClose, ...other }) => (
  <MuiDialogTitle disableTypography {...other}>
    <Typography variant="h5">{children}</Typography>
    {onClose ? (
      <IconButton aria-label="close" onClick={onClose}>
        <CloseIcon />
      </IconButton>
    ) : null}
  </MuiDialogTitle>
);

DialogTitle.defaultProps = {
  onClose: null,
};

DialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
};

export default DialogTitle;
