import React from 'react';
import PropTypes from 'prop-types';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import './styles.css';
import { Button } from '@material-ui/core';

const FireAuth = ({
  auth, googleButtonText, onSignInSuccess,
}) => {
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account',
    });

    await signInWithPopup(auth, provider);
    onSignInSuccess();
  };

  return (
    <div className="auth-container">
      <Button variant="contained" className="google-login-button" onClick={handleGoogleSignIn}>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/preceptor-link.appspot.com/o/Icons%2Fgoogle.svg?alt=media&token=b9740f4f-33d3-4169-9e98-52b3ea31b93f"
          alt="Google icon"
          className="google-logo"
        />
        {googleButtonText}
      </Button>
    </div>
  );
};

FireAuth.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  auth: PropTypes.any.isRequired,
  googleButtonText: PropTypes.string.isRequired,
  onSignInSuccess: PropTypes.func.isRequired,
};

export default FireAuth;
