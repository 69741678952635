import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '75px',
    height: '100vh',
    [theme.breakpoints.down('md')]: {
      height: 'calc (100vh - 152px)',
    },
  },
  withEmailVerificationBanner: {
    paddingTop: '139px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '75px',
    },
  },
}));
