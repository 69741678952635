import palette from '../palette';

export default {
  root: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderRadius: 8,
    border: `1px solid ${palette.grey['400']}`,
    filter: 'drop-shadow(0px 3px 4px rgba(233, 242, 242, 0.32))',
    '&::before': {
      display: 'none',
    },
    '&::after': {
      display: 'none',
    },
    backgroundColor: palette.background.paper,
    '&:hover': {
      backgroundColor: palette.background.paper,
      borderColor: palette.grey[500],
      filter: 'drop-shadow(0px 3px 4px rgba(233, 242, 242, 0.32))',
      '& $input': {
        borderColor: palette.grey[500],
      },
    },
    '&$marginDense': {
      borderRadius: 8,
    },
    '&$focused': {
      borderColor: palette.secondary.main,
      backgroundColor: palette.background.paper,
      filter: 'drop-shadow(0px 3px 4px rgba(31, 185, 120, 0.12))',
    },
    '&$error': {
      background: palette.background.paper,
      border: `1px solid ${palette.error.main}`,
      boxShadow: '0px 2px 4px rgba(246, 68, 68, 0.08)',
      filter: 'none',
    },
    '&$disabled': {
      filter: 'none',
      pointerEvents: 'none',
      border: 'none',
      backgroundColor: palette.grey[100],
      color: palette.grey[500],
    },
  },
  input: {
    fontWeight: 400,
    fontSize: '0.875rem',
    letterSpacing: '0.011em',
    lineHeight: 1.43,
    borderRadius: 8,
    background: 'transparent',
    paddingTop: '26px',
    paddingBottom: '6px',
    color: palette.text.secondary,
    '&$disabled': {
      pointerEvents: 'none',
      background: palette.grey[100],
      color: palette.grey[500],
    },
    '&$inputMarginDense': {
      paddingTop: '17px',
      paddingBottom: '3px',
      borderRadius: 'inherit',
    },
  },
  inputHiddenLabel: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  inputMultiline: {
    borderRadius: 0,
  },
};

