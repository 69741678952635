import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import BigPreceptorCard from 'shared/components/PreceptorCard/partials/BigPreceptorCard';
import SmallPreceptorCard from 'shared/components/PreceptorCard/partials/SmallPreceptorCard';
import paths from 'paths';

const PreceptorCard = ({
  accountUUID, accountUID, generalLocation, credentials, typeOfPractice,
  availability, specialty, averageNumberOfPatientsPerDay, small,
}) => {
  const history = useHistory();
  const { search } = useLocation();

  const credentialsString = credentials.map(el => el.value).join(', ');

  const handleClick = event => {
    event.stopPropagation();
    history.push({
      pathname: paths.searchPublicPaths.publicSearchPreceptor.reverse({ uuid: accountUUID }),
      search,
    });
  };

  if (small) {
    return (
      <SmallPreceptorCard
        accountUID={accountUID}
        generalLocation={generalLocation}
        typeOfPractice={typeOfPractice}
        availability={availability}
        specialty={specialty}
        averageNumberOfPatientsPerDay={averageNumberOfPatientsPerDay}
        credentialsString={credentialsString}
        handleClick={handleClick}
      />
    );
  }
  return (
    <BigPreceptorCard
      accountUID={accountUID}
      generalLocation={generalLocation}
      typeOfPractice={typeOfPractice}
      availability={availability}
      specialty={specialty}
      averageNumberOfPatientsPerDay={averageNumberOfPatientsPerDay}
      credentialsString={credentialsString}
      handleClick={handleClick}
    />
  );
};

PreceptorCard.defaultProps = {
  accountUID: '',
  generalLocation: '',
  credentials: [],
  typeOfPractice: [],
  availability: '',
  specialty: [],
  averageNumberOfPatientsPerDay: null,
  small: false,
};

PreceptorCard.propTypes = {
  accountUUID: PropTypes.string.isRequired,
  accountUID: PropTypes.string,
  generalLocation: PropTypes.string,
  credentials: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })),
  typeOfPractice: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })),
  availability: PropTypes.number,
  specialty: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })),
  averageNumberOfPatientsPerDay: PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  }),
  small: PropTypes.bool,
};

export default PreceptorCard;
