import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const tableStyles = makeStyles(theme => createStyles({
  root: {
    width: '100%',
  },
  // paper: {
  //   width: '100%',
  //   margin: theme.spacing(2),
  //   display: 'contents',
  // borderRadius: '20px',
  // border: `1px solid ${theme.palette.grey[300]}`,
  // },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  status: {
    color: theme.palette.secondary.main,
  },
  action: {
    color: theme.palette.icon,
  },
}));

export default tableStyles;
