import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const stepsBlockStyles = makeStyles(theme => createStyles({
  block: {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 20,
    padding: theme.spacing(4),
  },
  text: {
    marginBottom: theme.spacing(1),
  },
  number: {
    color: 'white',
  },
  circle: {
    borderRadius: '50%',
    width: 48,
    height: 48,
    background: theme.palette.gradients.brandingMain,
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  divider: {
    height: '100%',
    width: 2,
  },
  blockDivider: {
    height: 'calc(100% - 56px)',
  },
}));

export default stepsBlockStyles;
