import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import blockSearchRecommendationsStyles from './styles';
import Recommendations from './partials/Recommendations';

const BlockSearchRecommendations = () => {
  const classes = blockSearchRecommendationsStyles();
  return (
    <>
      <Box
        className={classes.block}
      >
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Typography variant="h6">
            Search Recommendations
          </Typography>
          <CloseIcon className={classes.iconClose} />
        </Box>
        <Typography className={classes.text}>
          You may find more preceptors by exploring Compact States, if you are open
          for travelling there:
        </Typography>
        <Recommendations preceptorCount="30 Preceptors in Washington" rating={4.8} cost={1500} />
        <Box
          className={classes.blockSearch}
        >
          <Recommendations preceptorCount="15 Preceptors in Colorado" rating={4.0} cost={1250} />
        </Box>
        <Recommendations preceptorCount="23 Preceptors in Iowa" rating={4.9} cost={1570} />
        <Box className={classes.button}>
          <Button
            variant="outlined"
            size="medium"
            fullWidth
          >
            View More
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default BlockSearchRecommendations;
