import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
