import {
  OPEN_SIGN_IN,
  OPEN_SIGN_UP,
  OPEN_FORGOT_PASSWORD,
  OPEN_SELECT_ROLE,
  CLOSE_ALL,
} from 'actions/authDialogConstants';

const initialState = {
  isSignInOpen: false,
  isSignUpOpen: false,
  isForgotPasswordOpen: false,
  isSelectRoleOpen: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SIGN_IN: {
      return {
        ...initialState,
        isSignInOpen: true,
      };
    }
    case OPEN_SIGN_UP: {
      return {
        ...initialState,
        isSignUpOpen: true,
      };
    }
    case OPEN_FORGOT_PASSWORD: {
      return {
        ...initialState,
        isForgotPasswordOpen: true,
      };
    }
    case OPEN_SELECT_ROLE: {
      return {
        ...initialState,
        isSelectRoleOpen: true,
      };
    }
    case CLOSE_ALL: {
      return initialState;
    }

    default:
      return state;
  }
};
