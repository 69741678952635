import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import typography from '../../../../theme/typography';

const searchRecommendations = makeStyles(theme => createStyles({
  block: {
    padding: 24,
    display: 'flex',
    width: '100%',
    maxWidth: '826px',
  },
  iconClose: {
    color: theme.palette.icon,
  },
  text: {
    ...typography.subtitle3,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: 5,
  },
}));

export default searchRecommendations;
