import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const rotationCostBreakdownStyles = makeStyles(theme => createStyles({
  iconDollar: {
    color: theme.palette.secondary.main,
    borderRadius: 10,
  },
  blockDollar: {
    background: theme.palette.colors.powderyGreen,
    borderRadius: 10,
    padding: 6,
    marginRight: theme.spacing(2),
    display: 'flex',
  },
  textHint: {
    color: theme.palette.text.hint,
  },
  tooltip: {
    background: '#585858',
  },
  tooltipText: {
    color: '#fff',
    fontSize: '12px !important',
  },
  discountedPrice: {
    textDecoration: 'line-through',
    // opacity: 0.6,
    paddingRight: 6,
    fontWeight: 'normal',
    fontSize: '0.875rem',
  },
}));

export default rotationCostBreakdownStyles;
