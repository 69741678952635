import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Slider from '@material-ui/core/Slider';

const Sliders = () => {
  const [sliderValue, setSliderValue] = React.useState([0, 50]);

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  return (

    <Box mb={4} mt={4}>
      <Typography variant="h5" align="center" gutterBottom>
        Slider
      </Typography>
      <Grid container justify="center" alignItems="baseline" spacing={2}>
        <Grid item>
          <Box minWidth={300}>
            <Slider
              value={sliderValue}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              aria-labelledby="continuous-slider"
              marks={[
                {
                  value: 0,
                  label: '0',
                },
                {
                  value: 50,
                  label: '50',
                },
                {
                  value: 100,
                  label: '100',
                },
              ]}
            />
          </Box>
        </Grid>
        <Grid item>
          <Box minWidth={300}>
            <Slider
              disabled
              defaultValue={50}
              valueLabelDisplay="auto"
              aria-labelledby="continuous-slider"
              marks={[
                {
                  value: 0,
                  label: '0',
                },
                {
                  value: 50,
                  label: '50',
                },
                {
                  value: 100,
                  label: '100',
                },
              ]}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Sliders;
