import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => createStyles({
  appBar: {
    background: theme.palette.white,
    flexGrow: 1,
    minHeight: 75,
    borderBottom: `1px solid ${theme.palette.grey['400']}`,
  },
  brandingMainGradient: {
    background: theme.palette.gradients.brandingMain,
    backgroundClip: 'text',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  verticalDivider: {
    height: '1rem',
    background: theme.palette.gradients.brandingMain,
    margin: '0 0.5rem',
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  logo: {
    margin: '16px 0 16px 0',
  },
  preceptorSignUpLabel: {
    marginRight: '1.5rem',
  },
  buttonLogin: {
    fontSize: '0.938rem',
    lineHeight: 1.2,
    fontWeight: 500,
    letterSpacing: '0.029em',
    padding: '11px 22px',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  buttonSignUp: {
    fontSize: '0.938rem',
    lineHeight: 1.2,
    fontWeight: 500,
    letterSpacing: '0.029em',
    padding: '12px 22px',
    borderRadius: '8px',
  },
}));
