import React from 'react';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// import Link from '@material-ui/core/Link';
import firstBlockStyles from './styles';

const FirstBlock = () => {
  const classes = firstBlockStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box>
      <Typography
        variant={isMobile ? 'h5' : 'h2'}
        className={classes.text}
      >
        Custom Search by PreceptorLink
        <sup className={classes.tradeMark}>&reg;</sup>
      </Typography>
      <Typography
        variant={isMobile ? 'subtitle2' : 'subtitle1'}
        color="textSecondary"
        className={classes.textGrey}
      >
        Hand the burden of finding a preceptor over to our
        team of preceptor- matching experts! We are here to
        support you in your educational endeavors!
      </Typography>
      {/* <Box mt={1.5}>
        <Link
          href="https://www.preceptorlink.com/Cancellation-and-Refund-Policy.html"
          target="_blank"
          className={classes.link}
        >
          <Box display="flex">
            <Box>
              <Typography className={classes.textLink}>
                Learn More
              </Typography>
            </Box>
            <Box>
              <ArrowForwardIosIcon className={classes.icon} />
            </Box>
          </Box>
        </Link>
      </Box> */}
      <img
        src="https://firebasestorage.googleapis.com/v0/b/preceptor-link.appspot.com/o/Images%2FmarketingPage.svg?alt=media&token=2ce00d40-e98b-40aa-84b1-c81f76069636"
        alt="marketing-page"
        className={classes.image}
      />
    </Box>
  );
};

export default FirstBlock;
