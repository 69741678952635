import palette from '../palette';

export default {
  root: {
    borderRadius: 'none',
    '&$selected': {
      backgroundColor: palette.backgrounds.backgroundRangeSelectedDate,
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: palette.secondary.light,
      },
    },
    '&:focus': {
      backgroundColor: palette.secondary.dark,
      '&$selected': {
        backgroundColor: palette.backgrounds.backgroundSelectedDate,
      },
      '&:not($selected)': {
        border: '0 !important',
      },
    },
    '&$disabled': {
      backgroundColor: `${palette.grey[200]} !important`,
      border: `1px solid ${palette.grey[200]}`,
      color: palette.colors.lightgrey,
    },
  },
  today: {
    '&:not($selected)': {
      border: `1px solid ${palette.divider}`,
    },
  },
  label: {
    backgroundColor: palette.secondary.dark,
  },
};
