import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const specialtyBlockStyles = makeStyles(theme => createStyles({
  block: {
    maxWidth: '70%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      width: '100%',
    },
  },
  text: {
    [theme.breakpoints.down('md')]: {
      maxWidth: '85%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
}));

export default specialtyBlockStyles;
