import palette from '../palette';

export default {
  rangeCalendarContainer: {
    '&:not(:last-child)': {
      position: 'relative',
      '&:before': {
        position: 'absolute',
        content: '" "',
        right: 0,
        top: '24px',
        bottom: '24px',
        width: '1px',
        background: palette.divider,
      },
      borderRight: 'none',
    },
  },
  calendar: {
    minHeight: '260px',
  },
};
