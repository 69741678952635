import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import clsx from 'clsx';
import partiallyAvailableStyles from './styles';

const PartiallyAvailable = ({ hours, rightRectangle }) => {
  const classes = partiallyAvailableStyles();
  const title = `Partially available (${hours || 0} hrs)`;

  return (
    <Box className={clsx(classes.block, {
      [classes.right]: rightRectangle,
    })}
    >
      <QueryBuilderIcon fontSize="small" className={classes.iconClock} />
      <Typography variant="subtitle2" className={classes.partially}>
        {title}
      </Typography>
    </Box>
  );
};

PartiallyAvailable.defaultProps = {
  hours: '',
  rightRectangle: false,
};

PartiallyAvailable.propTypes = {
  hours: PropTypes.number,
  rightRectangle: PropTypes.bool,
};

export default PartiallyAvailable;
