import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import TypeOfPractice from 'shared/components/TypeOfPractice';
import PatientsNumberBlock from 'shared/components/PatientsNumberBlock';
import rightBlockCardStyles from './styles';

const RightBlockCard = ({
  typeOfPractice, generalLocation, averageNumberOfPatientsPerDay,
}) => {
  const classes = rightBlockCardStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box display="flex" flexDirection="column" ml={isMobile ? 1 : 5} alignItems="flex-start" pr={1}>
      <TypeOfPractice typeOfPractice={typeOfPractice} limit={isMobile ? 2 : 4} />
      {generalLocation && (
      <Box display="flex" alignItems="center" mb={1}>
        <LocationOnIcon fontSize="small" className={classes.icon} />
        <Typography variant="subtitle2">
          {generalLocation}
        </Typography>
      </Box>
      )}
      {averageNumberOfPatientsPerDay?.value && (
        <PatientsNumberBlock averageNumberOfPatientsPerDay={averageNumberOfPatientsPerDay} />
      )}
    </Box>
  );
};

RightBlockCard.defaultProps = {
  generalLocation: '',
  typeOfPractice: [],
  averageNumberOfPatientsPerDay: null,
};

RightBlockCard.propTypes = {
  generalLocation: PropTypes.string,
  typeOfPractice: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })),
  averageNumberOfPatientsPerDay: PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  }),
};

export default RightBlockCard;
