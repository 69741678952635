import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import palette from '../../../../theme/palette';
import typography from '../../../../theme/typography';

const chatStyles = makeStyles(theme => createStyles({
  root: {
    background: theme.palette.backgrounds.backgroundChat2,
    border: `1px solid ${palette.colors.lightPrimaryBlue}`,
    boxShadow: '0px 3px 4px rgba(233, 242, 242, 0.32)',
    borderRadius: 16,
  },
  block: {
    // position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(1),
    },
  },
  iconClose: {
    // position: 'absolute',
    // top: 0,
    // right: 0,
    height: '40px',
    marginTop: '7px',
  },
  text: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(1),
    },
  },
  textChat: {
    textAlign: 'center',
    marginTop: theme.spacing(0.5),
    ...typography.subtitle2,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('md')]: {
      ...typography.subtitle3,
      color: theme.palette.text.secondary,
    },
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  customSearchLink: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

export default chatStyles;
