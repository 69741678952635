import RoutePath from 'shared/utils/namedUrls';

const basePath = '/';

export default {
  verifyEmail: new RoutePath(`${basePath}verification/:hashid`),
  privacyPolicy: new RoutePath(`${basePath}privacy-policy`),
  termsAndConditions: new RoutePath(`${basePath}terms-and-conditions`),
  refundPolicy: new RoutePath(`${basePath}refund-policy`),
};
