import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import typography from '../../../theme/typography';

const typeOfPracticeStyles = makeStyles(theme => createStyles({
  communityBlock: {
    display: 'inline-flex',
    alignItems: 'center',
    border: `1px solid ${theme.palette.colors.borderTypePractice}`,
    borderRadius: 8,
    padding: '1px 8px',
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    maxWidth: '100%',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  communityText: {
    color: theme.palette.primary.dark,
    maxWidth: '100%',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tagCommunity: {
    ...typography.subtitle2,
    backgroundColor: theme.palette.grey[200],
    padding: '1px 8px',
    borderRadius: 8,
    display: 'inline-flex',
    color: theme.palette.primary.dark,
  },
}));

export default typeOfPracticeStyles;
