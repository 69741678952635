import typography from '../typography';

export default {
  root: {
    ...typography.subtitle2,
  },
  ul: {
    ...typography.subtitle2,
  },
};
