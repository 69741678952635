import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const smallPreceptorCardStyles = makeStyles(theme => createStyles({
  block: {
    background: theme.palette.background.paper,
    padding: '24px 0 24px 24px',
    display: 'flex',
    width: '100%',
    maxWidth: '520px',
    '&:hover': {
      background: theme.palette.grey[50],
      cursor: 'pointer',
    },
    [theme.breakpoints.up('xs')]: {
      padding: '8px 0 8px 8px',
      maxWidth: 'auto',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      maxWidth: 'auto',
    },
    '& .MuiChip-root': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: '100%',
    },
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default smallPreceptorCardStyles;
