import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authDialogsState from 'reducers/authDialogsState';
import snackbarState from 'reducers/snackbarState';

const createRootReducer = history => combineReducers({
  router: connectRouter(history),
  authDialogsState,
  snackbar: snackbarState,
});

export default createRootReducer;
