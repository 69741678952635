import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const customSearchPageStyles = makeStyles(theme => createStyles({
  block: {
    background: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 20,
    padding: theme.spacing(3),
    minWidth: 350,
    [theme.breakpoints.down('sm')]: {
      minWidth: 300,
    },
  },
  backgroundBlock: {
    maxHeight: 610,
    position: 'relative',
    background: theme.palette.grey[100],
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      background: 'transparent',
      maxHeight: 'none',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '140px',
      bottom: 0,
      transform: 'rotate(180deg)',
      backgroundImage: 'url("https://firebasestorage.googleapis.com/v0/b/preceptor-link.appspot.com/o/Bg%2Ftriangle.svg?alt=media&token=d3ca0ef4-958c-4cb1-89d8-ca81b4f6feb9")',
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.down('sm')]: {
        background: 'none',
      },
    },
  },
  grid: {
    position: 'relative',
    zIndex: 2,
  },
}));

export default customSearchPageStyles;
