import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CheckBoxContainer = () => (
  <Box>
    <Typography variant="h5" align="center" gutterBottom>
      Checkbox
    </Typography>
    <Grid container justify="center" alignItems="baseline" spacing={2}>
      <Grid item display="flex" alignItems="center">
        <FormControlLabel
          control={<Checkbox color="primary" />}
          label="Checkbox"
        />
      </Grid>
      <Grid item>
        <Checkbox
          defaultChecked
        />
      </Grid>
      <Grid item>
        <Checkbox
          defaultChecked
          indeterminate
        />
      </Grid>
      <Grid item>
        <Checkbox
          disabled
        />
      </Grid>
      <Grid item>
        <Checkbox
          defaultChecked
          disabled
        />
      </Grid>
      <Grid item display="flex" alignItems="center">
        <FormControlLabel
          control={<Checkbox />}
          label="Checkbox"
          disabled
        />
      </Grid>
    </Grid>
  </Box>
);

export default CheckBoxContainer;
