export default {
  root: {
    margin: '16px 0',
  },
  rounded: {
    '&:last-child': {
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    },
    '&:first-child': {
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
  },
};
