import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import { formatDateShort } from 'shared/helpers/dateHelpers';
import Link from '@material-ui/core/Link';
import { BCC_DEFAULT_EMAIL } from 'shared/constants/sharedConstants';
import sectionTagDateHoursStyles from './styles';
import RotationInfoItem from '../RotationInfoItem';

const SectionTagDateHours = ({
  startDate, endDate, paperworkDueDate, paymentType, hours, owners, rotationUID,
}) => {
  const classes = sectionTagDateHoursStyles();
  const mailtoSubject = rotationUID ? `subject=Rotation <${rotationUID}>` : '';

  return (
    <Box display="flex" flexDirection="column" alignItems="start">
      <Grid container mt={2}>
        <Grid item xs={6}>
          <RotationInfoItem
            title="Timeline"
            value={`${formatDateShort(startDate)} - ${formatDateShort(endDate)}`}
          />
        </Grid>
        {paperworkDueDate && (
          <Grid item xs={6}>
            <RotationInfoItem
              title="Paperwork Due"
              value={formatDateShort(paperworkDueDate)}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <RotationInfoItem
            title="# of Hours"
            value={hours}
          />
        </Grid>
        {paymentType && (
          <Grid item xs={6} md={2}>
            <RotationInfoItem
              title="Payment Type"
              value={paymentType?.value}
            />
          </Grid>
        )}
        <RotationInfoItem
          title="Clinical Coordinators"
          value={(
            <Link
              className={classes.link}
              href={`mailto:${owners.map(owner => owner.user.email).join(',')}?bcc=${BCC_DEFAULT_EMAIL}&${mailtoSubject}`}
              target="_blank"
              rel="noreferrer"
              onClick={e => e.stopPropagation()}
            >
              {owners.map(owner => owner.user.getName).join(', ')}
            </Link>
          )}
        />
      </Grid>
    </Box>
  );
};

SectionTagDateHours.defaultProps = {
  paperworkDueDate: '',
  paymentType: null,
};

SectionTagDateHours.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  paperworkDueDate: PropTypes.string,
  hours: PropTypes.number.isRequired,
  paymentType: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  owners: PropTypes.arrayOf(PropTypes.shape({
    user: PropTypes.shape({
      getName: PropTypes.string,
      email: PropTypes.string,
    }),
  })).isRequired,
  rotationUID: PropTypes.string.isRequired,
};

export default SectionTagDateHours;
