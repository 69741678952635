import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import {
  Box, Typography, MenuItem, FormControl,
  Checkbox, FormControlLabel, InputLabel, Select,
} from '@material-ui/core';
import paymentStatuses from 'apps/finances/constants/paymentStatuses';
import { MY_ROTATIONS, ROTATION } from 'shared/queries/rotation/queries';
import {
  CREATE_PAYMENT_INTENT, MY_PAYMENT_METHODS, MY_PAYMENTS, CHANGE_ROTATION_STATUS, SET_PROCESSING_DEPOSIT,
} from 'shared/queries/finances/queries';
import SNACKBAR_OPEN from '../../../../../../actions/snackbarAction';
import useWizardDecisionAndRedirect from '../../../../../wizard/hooks/useWizardDecisionAndRedirect';
import paymentBlockStyles from './styles';

const PaymentBlock = ({
  setEmpty, setProcessing, error, setError, errorAdd, setErrorAdd,
  paymentDescription, setCloseDialog, redirectTo, rotationUUID, dueToday, initialTotal,
}) => {
  useWizardDecisionAndRedirect();

  const dispatch = useDispatch();
  const classes = paymentBlockStyles();
  const history = useHistory();

  const { data: { myPayments = {} } = {} } = useQuery(
    MY_PAYMENTS, { variables: { rotationUuid: rotationUUID } },
  );
  const alreadyPaid = (dueToday === 0 && initialTotal > 0) || myPayments.results?.some(
    payment => payment.description.key === paymentDescription && payment.status.key !== paymentStatuses.failed
      && payment.paymentAmount === dueToday,
  );

  const [clientSecret, setClientSecret] = useState(null);
  const [clientSecretAdd, setClientSecretAdd] = useState(null);

  const { loading, data: { myPaymentMethods = {} } = {} } = useQuery(MY_PAYMENT_METHODS);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectCard, setSelectCard] = useState(false);
  const [addCard, setAddCard] = useState(true);

  const [createPaymentIntentMutation] = useMutation(CREATE_PAYMENT_INTENT);
  const [changeRotationStatusMutation] = useMutation(CHANGE_ROTATION_STATUS);
  const [setProcessingDepositMutation] = useMutation(SET_PROCESSING_DEPOSIT);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (!loading && myPaymentMethods.length) {
      setSelectCard(true);
      setSelectedCard(myPaymentMethods.find(item => item.isDefault)?.stripeUid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (alreadyPaid) return;
    createPaymentIntentMutation({
      variables: { uuid: rotationUUID, description: paymentDescription, addCard: false },
    }).then(({ data: { createPaymentIntent } = {} }) => {
      if (createPaymentIntent?.status === 'Error') {
        setError(createPaymentIntent?.clientSecret);
      } else {
        setClientSecret(createPaymentIntent?.clientSecret);
      }
    }).catch(mutationError => {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        variant: 'alert',
        alertSeverity: 'error',
        message: mutationError.toString(),
      });
    });
    createPaymentIntentMutation({
      variables: { uuid: rotationUUID, description: paymentDescription, addCard: true },
    }).then(({ data: { createPaymentIntent } = {} }) => {
      if (createPaymentIntent?.status === 'Error') {
        setErrorAdd(createPaymentIntent?.clientSecret);
      } else {
        setClientSecretAdd(createPaymentIntent?.clientSecret);
      }
    }).catch(mutationError => {
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        variant: 'alert',
        alertSeverity: 'error',
        message: mutationError.toString(),
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alreadyPaid]);

  const cardStyle = {
    style: {
      base: { color: '#1A2626', '::placeholder': { color: '#93AAAA' } },
      invalid: { color: '#F64444', iconColor: '#F64444' },
    },
  };

  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  };

  const handleChangeAddCard = async event => {
    setAddCard(!event.target.checked);
    setEmpty(!event.target.checked);
    setError(null);
    setErrorAdd(null);
  };

  const handleChangeCardSelect = async event => {
    setSelectedCard(event.target.value);
    setError(null);
  };

  const handleChangeCardElement = async event => {
    if (addCard) {
      setEmpty(event.empty);
    }
    if (event.error) {
      setErrorAdd(event.error.message);
    } else {
      setErrorAdd(null);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setProcessing(true);

    if (alreadyPaid) {
      changeRotationStatusMutation({
        variables: { uuid: rotationUUID, description: paymentDescription },
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: MY_ROTATIONS },
          { query: ROTATION, variables: { uuid: rotationUUID } },
        ],
      }).finally(() => {
        setCloseDialog();
        history.push({ pathname: redirectTo.reverse({ uuid: rotationUUID }), search: '?update=true' });
      });
    } else {
      const result = addCard ? await stripe.confirmCardPayment(clientSecretAdd, {
        payment_method: { card: elements.getElement(CardElement) },
      }) : await stripe.confirmCardPayment(clientSecret, {
        payment_method: selectedCard,
      });
      if (result.error) {
        if (addCard) {
          setErrorAdd(result.error.message);
        } else {
          setError(result.error.message);
        }
        setProcessing(false);
      } else {
        setProcessingDepositMutation({
          variables: {
            rotationUuid: rotationUUID,
            processingDeposit: true,
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            { query: MY_ROTATIONS },
            { query: ROTATION, variables: { uuid: rotationUUID } },
          ],
        }).finally(() => {
          setCloseDialog();
          history.push({ pathname: redirectTo.reverse({ uuid: rotationUUID }), search: '?update=true' });
        });
        window.gtag(
          'event',
          'purchase',
          {
            value: 1,
            currency: 'USD',
            items: [
              {
                item_id: rotationUUID,
                item_name: paymentDescription,
                item_category: 'deposit',
                quantity: 1,
                price: dueToday,
              },
            ],
          },
        );
      }
    }
  };

  return (
    <Box className={classes.block}>
      <form id="pay_and_reserve" onSubmit={handleSubmit}>
        <Typography variant="h5">{selectCard ? 'Payment' : 'Add Payment Card'}</Typography>
        <Box mt={3}>
          {clientSecret && selectCard && (
            <Box>
              <Box ml={-1.5} my={2}>
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label="Use my payment card on file"
                  onChange={handleChangeAddCard}
                />
              </Box>
              <FormControl variant="filled" fullWidth disabled={addCard} error={Boolean(error)}>
                <InputLabel htmlFor="filled-age-native-simple" shrink={Boolean(selectedCard)}>Payment Card</InputLabel>
                <Select fullWidth MenuProps={menuProps} value={selectedCard} onChange={handleChangeCardSelect}>
                  {!loading && myPaymentMethods.map(item => (
                    <MenuItem key={item.uuid} value={item.stripeUid}>
                      **** **** ****
                      {' '}
                      {item.last4}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {error && (
                <Box p={1}>
                  <Typography color="error" variant="subtitle2">{error}</Typography>
                </Box>
              )}
            </Box>
          )}
          {clientSecretAdd && addCard && (
            <Box mt={3}>
              <Box p={2} border={1} borderRadius={15} borderColor={errorAdd ? '#F64444' : '#E9F2F2'}>
                <CardElement options={cardStyle} onChange={handleChangeCardElement} />
              </Box>
              {errorAdd && (
                <Box p={1}>
                  <Typography color="error" variant="subtitle2">{errorAdd}</Typography>
                </Box>
              )}
            </Box>
          )}
          {!clientSecret && !clientSecretAdd && (
            <Typography color="textSecondary" variant="subtitle2">
              {alreadyPaid
                ? 'You have already paid deposit for this rotation. No payment required at this step.'
                : 'Please wait a few seconds to load the payment block. Otherwise, try to refresh the page...'}
            </Typography>
          )}
        </Box>
      </form>
    </Box>
  );
};

PaymentBlock.defaultProps = {
  setCloseDialog: () => {},
  dueToday: null,
  initialTotal: null,
};

PaymentBlock.propTypes = {
  setEmpty: PropTypes.func.isRequired,
  setProcessing: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  setError: PropTypes.func.isRequired,
  errorAdd: PropTypes.string.isRequired,
  setErrorAdd: PropTypes.func.isRequired,
  paymentDescription: PropTypes.string.isRequired,
  setCloseDialog: PropTypes.func,
  redirectTo: PropTypes.shape.isRequired,
  rotationUUID: PropTypes.string.isRequired,
  dueToday: PropTypes.number,
  initialTotal: PropTypes.number,
};

export default PaymentBlock;
