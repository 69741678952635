import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const Switchs = () => (
  <Box>
    <Typography variant="h5" align="center" gutterBottom>
      Switch
    </Typography>
    <Grid container justify="center" alignItems="baseline" spacing={2}>
      <Grid item display="flex" alignItems="center">
        <FormControlLabel
          control={<Switch />}
          label="Switch"
        />
      </Grid>
      <Grid item>
        <Switch
          checked
        />
      </Grid>
      <Grid item display="flex" alignItems="center">
        <FormControlLabel
          control={<Switch />}
          label="Switch"
          disabled
          checked
        />
      </Grid>
    </Grid>
  </Box>
);

export default Switchs;
