import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { useAuth, useSigninCheck } from 'reactfire';
import { useDispatch } from 'react-redux';
import { useBackendUserState } from 'contexts/UserContext';
import useQueryString from 'shared/hooks/useQueryString';
import useWizardDecisionAndRedirect from 'apps/wizard/hooks/useWizardDecisionAndRedirect';
import {
  Avatar, Grid, Link, Typography, Container,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CssBaseline from '@material-ui/core/CssBaseline';
import SignInForm from 'apps/account/components/SignInForm';
import CircularProgressCustom from 'shared/components/CircularProgressCustom';
import { openForgotPassword } from 'actions/authDialogActions';
import { USER_LOGGED_IN_MUTATION } from 'shared/queries/account/mutations';
import FireAuth from 'shared/components/FireAuth';
import signInStyles from 'apps/account/pages/SignIn/styles';
import paths from 'paths';
import DividerOr from 'shared/components/DividerOr';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { signInWithEmailAndPassword } from 'firebase/auth';

const SignIn = () => {
  const classes = signInStyles();
  const auth = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: signInCheckResult } = useSigninCheck();
  const [submitErrors, setSubmitErrors] = useState('');
  const [loading, setLoading] = useState(false);
  const { urlParams, setQueryString } = useQueryString();
  const { state: { user: backendUser } } = useBackendUserState();
  const { wizard, wizardLink } = useWizardDecisionAndRedirect();
  const isUserAuthenticated = signInCheckResult.signedIn;
  const { next: nextPath } = urlParams;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [userLoggedIn] = useMutation(USER_LOGGED_IN_MUTATION);

  useEffect(() => {
    if (isUserAuthenticated) {
      if (wizard && backendUser) {
        const wizardCompleted = backendUser?.wizardState?.isWizardCompleted;
        const path = wizardCompleted ? nextPath || paths.rotationPaths.all.reverse() : wizardLink;
        history.push(path);
      } else {
        setLoading(true);
      }
    }
  }, [isUserAuthenticated, wizard, wizardLink, nextPath, backendUser, history]);

  const onSignInSubmit = data => {
    signInWithEmailAndPassword(auth, data.email, data.password).then(() => {
      setSubmitErrors('');
      setLoading(true);
      setQueryString({ ...urlParams });
      userLoggedIn({ variables: { description: 'to the "Application"' } });
    }).catch(reason => {
      setSubmitErrors(reason?.code);
    });
  };

  const handleOpenForgotPassword = e => {
    e.preventDefault();
    dispatch(openForgotPassword());
  };

  if (loading) {
    return <CircularProgressCustom height="100vh" />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography className={classes.title} component="h1" variant="h5">
          Sign in
        </Typography>
        <SignInForm onSubmit={onSignInSubmit} submitErrors={submitErrors} />
        <DividerOr />
        <div style={{ width: !isMobile ? '22rem' : '100%' }}>
          <FireAuth
            auth={auth}
            googleButtonText="Login with Google Account"
            linkedinButtonText="Login with Linkedin Account"
            onSignInSuccess={() => userLoggedIn({ variables: { description: 'to the "Application" using a Google account' } })}
          />
        </div>
        <Grid container>
          <Grid item xs>
            <Link href="/account/sign-up" variant="body2">
              Don&apos;t have an account? Sign Up
            </Link>
          </Grid>
          <Grid item>
            <Link href="/account/sign-in" onClick={handleOpenForgotPassword} variant="body2">
              Forgot password?
            </Link>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default SignIn;
