import { gql } from '@apollo/client';

export const SAVE_ROTATION_STEP_MUTATION = gql`
  mutation saveRotationStep($submittedData: JSONString!) {
    saveRotationStep(submittedData: $submittedData) {
      status
      message
    }
  }
`;

export const SAVE_WIZARD_STEP_MUTATION = gql`
  mutation wizardStepSubmitted($stepUuid: UUID!, $submittedData: JSONString!, $finish: Boolean!) {
    wizardStepSubmitted(stepUuid: $stepUuid, submittedData: $submittedData, finish: $finish) {
      submitted
      rotationUuid
      message
    }
  }
`;

export const UPLOAD_WIZARD_ATTACHMENT_MUTATION = gql`
  mutation uploadWizardStepAttachment($file: Upload!, $stepSlug: String!, $fieldName: String!) {
    uploadWizardStepAttachment(file: $file, stepSlug: $stepSlug, fieldName: $fieldName) {
      fileUrl
    }
  }
`;

export const DELETE_WIZARD_ATTACHMENT_MUTATION = gql`
  mutation deleteWizardStepAttachment($stepSlug: String!, $fieldName: String!) {
    deleteWizardStepAttachment(stepSlug: $stepSlug, fieldName: $fieldName) {
      status
      message
    }
  }
`;
