import palette from '../palette';

export default {
  root: {
    fontWeight: 400,
    fontSize: '0.875rem',
    letterSpacing: '0.011em',
    lineHeight: 1.43,
    color: palette.text.primary,
    '&$focused': {
      fontWeight: 400,
      fontSize: '0.75rem',
      letterSpacing: '0.03333em',
      lineHeight: 1,
      color: palette.secondary.main,
    },
    '&$disabled': {
      color: palette.grey[500],
      filter: 'none',
    },
    '&$shrink': {
      fontWeight: 400,
      fontSize: '0.75rem',
      letterSpacing: '0.03333em',
      lineHeight: 1,
    },
  },
};
