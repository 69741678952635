import { Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const PaymentsPercentageBreakdown = ({ total, previousRequestsPaidAmount }) => {
  const discountOnSecondPart = previousRequestsPaidAmount > total / 4;
  const discountOnBalance = previousRequestsPaidAmount > total * (3 / 4);

  return (
    <>
      <Typography variant="subtitle2">
        <span style={{ fontWeight: 'bold' }}>Deposit:</span>
        {' '}
        25% of total fees
        {previousRequestsPaidAmount > 0 && ' minus discount'}
      </Typography>
      <Typography variant="subtitle2">
        <span style={{ fontWeight: 'bold' }}>Preceptor Acceptance:</span>
        {' '}
        50% of total fees
        {discountOnSecondPart && ' minus discount'}
      </Typography>
      <Typography variant="subtitle2">
        <span style={{ fontWeight: 'bold' }}>Initial Paperwork Signed:</span>
        {' '}
        25% of total fees
        {discountOnBalance && ' minus discount'}
      </Typography>
    </>
  );
};

PaymentsPercentageBreakdown.propTypes = {
  total: PropTypes.number.isRequired,
  previousRequestsPaidAmount: PropTypes.number.isRequired,
};

export default PaymentsPercentageBreakdown;
