import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

export default {
  paper: {
    borderRadius: 16,
    minWidth: '500px',
    [breakpoints.down('md')]: {
      minWidth: 'auto',
      margin: 15,
    },
  },
};
