import React from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import dividerStyles from './styles';

const DividerOr = () => {
  const classes = dividerStyles();
  return (
    <Box
      display="flex"
      className={classes.root}
    >
      <Divider className={classes.divider} />
      <Typography variant="subtitle1" className={classes.text} color="textSecondary">
        OR
      </Typography>
      <Divider className={classes.divider} />
    </Box>
  );
};

export default DividerOr;
