export default {
  root: {
    padding: '0 0 0 16px',
    minHeight: 6 * 4,
    '&$expanded': {
      minHeight: 6 * 4,
    },
  },
  content: {
    marginTop: 0,
    marginBottom: 0,
    '&$expanded': {
      margin: 0,
    },
  },
};
