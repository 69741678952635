import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import palette from '../palette';
import typography from '../typography';

const breakpoints = createBreakpoints({});

export default {
  root: {
    ...typography.subtitle2,
    color: palette.text.primary,
    height: 40,
    minWidth: 40,
    margin: '0 4px',
    [breakpoints.down('md')]: {
      height: 35,
      minWidth: 35,
      margin: '0 1px',
    },
  },
  rounded: {
    borderRadius: 8,
  },
  page: {
    '&:hover': {
      backgroundColor: palette.grey[200],
    },
    '&$disabled': {
      color: palette.grey['100'],
      opacity: 1,
    },
    '&$selected': {
      backgroundColor: palette.primary.main,
      color: palette.white,
      '&:hover': {
        backgroundColor: palette.primary.dark,
        border: '1px solid transparent',
      },
      '&:active': {
        backgroundColor: palette.primary.light,
        border: '1px solid transparent',
      },
    },
  },
  icon: {
    color: palette.icon,
  },
};
