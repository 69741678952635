import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const conflictingRotationsStyles = makeStyles(theme => createStyles({
  conflictingRotation: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '100%',
    letterSpacing: '0.53px',
    color: theme.palette.warning.main,
    marginTop: theme.spacing(0.25),
  },
}));

export default conflictingRotationsStyles;
