import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const bigPreceptorCardStyles = makeStyles(theme => createStyles({
  block: {
    background: theme.palette.background.paper,
    padding: '24px 0 24px 24px',
    display: 'flex',
    width: '100%',
    maxWidth: '826px',
    '&:hover': {
      background: theme.palette.grey[50],
      cursor: 'pointer',
    },
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default bigPreceptorCardStyles;
