import React from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useAuth } from 'reactfire';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  Box, Typography, Button, TextField, Dialog, DialogContent,
} from '@material-ui/core';
import SNACKBAR_OPEN from 'actions/snackbarAction';
import { SEND_RESET_PASSWORD_LINK } from 'shared/queries/account/queries';
import DialogTitle from 'shared/components/DialogTitle';
import ButtonProgress from 'shared/components/ButtonProgress';
import { closeAll } from 'actions/authDialogActions';
import * as yup from 'yup';

const schema = yup.object().shape({
  email: yup.string().email('Email must be a valid email').required('Field is required'),
});

const ForgotPasswordDialog = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const [sendResetPasswordLink, { loading: mutationLoading }] = useMutation(SEND_RESET_PASSWORD_LINK);

  const {
    register, handleSubmit, setError, reset, formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const isForgotPasswordOpen = useSelector(
    state => state.authDialogsState.isForgotPasswordOpen, shallowEqual,
  );

  const handleClose = () => {
    dispatch(closeAll());
    reset();
  };

  const onSubmit = data => {
    sendResetPasswordLink({ variables: { email: data.email } }).then(response => {
      if (response.data.sendResetPasswordLink.status === 'Error') {
        setError('email', {
          type: 'manual',
          message: response.data.sendResetPasswordLink.message,
        });
      } else {
        sendPasswordResetEmail(auth, data.email)
          .then(() => {
            handleClose();
            dispatch({
              type: SNACKBAR_OPEN,
              open: true,
              variant: 'alert',
              alertSeverity: 'success',
              message: 'Password Reset Instructions has been sent on the given email',
            });
          })
          .catch((reason => {
            setError('email', {
              type: 'manual',
              message: reason.message,
            });
          }));
      }
    });
  };

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="forgot password dialog title" open={isForgotPasswordOpen}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Forgot Password?
        </DialogTitle>
        <DialogContent>
          <Box mb={2} maxWidth="430px">
            <Typography variant="subtitle2" color="textSecondary">
              Enter the email address you used when you joined, and we’ll send you instructions
              to reset your password.
            </Typography>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box mb={2}>
              <TextField
                label="Email"
                id="email"
                type="text"
                name="email"
                variant="filled"
                fullWidth
                {...register('email')}
                error={Boolean(errors.email?.message)}
                helperText={errors.email?.message}
              />
            </Box>
            <Box mb={3}>
              <Button variant="contained" size="large" type="submit" fullWidth disabled={mutationLoading}>
                Send Reset Instructions
                <ButtonProgress isLoading={mutationLoading} />
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ForgotPasswordDialog;
