import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const circularProgressCustomStyles = makeStyles(theme => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // height: '100vh',
    position: 'relative',
    justifySelf: 'center',
    alignSelf: 'center',
    flex: 1,
  },
  bottom: {
    color: theme.palette.grey[300],
  },
  top: {
    color: theme.palette.secondary.main,
    animationDuration: '550ms',
    position: 'absolute',
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

export default circularProgressCustomStyles;
