export default {
  root: {
    marginRight: 3,
    marginLeft: 0,
  },
  label: {
    fontWeight: 400,
    fontSize: '0.875rem',
    letterSpacing: '0.011em',
    lineHeight: 1.43,
  },
  labelPlacementBottom: {
    marginLeft: 0,
    marginRight: 0,
  },
};
