import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { ReactComponent as FireIcon } from 'assets/icons/fire.svg';
import clsx from 'clsx';
import patientsNumberBlockStyles from './styles';

const PatientsNumberBlock = ({ averageNumberOfPatientsPerDay }) => {
  const classes = patientsNumberBlockStyles();

  return (
    <Box display="flex" alignItems="center">
      {averageNumberOfPatientsPerDay.key > 10
        ? <FireIcon className={classes.fireIcon} />
        : <AssessmentIcon fontSize="small" className={classes.icon} />}
      <Typography
        variant="subtitle2"
        className={clsx({ [classes.numberPatients]: averageNumberOfPatientsPerDay.key > 10 })}
      >
        {averageNumberOfPatientsPerDay.value}
      </Typography>
      <Typography className={classes.numberText}>
        patients/day
      </Typography>
    </Box>
  );
};

PatientsNumberBlock.propTypes = {
  averageNumberOfPatientsPerDay: PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  }).isRequired,
};

export default PatientsNumberBlock;
