import formatISO from 'date-fns/formatISO';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { dateFormat, dateShortFormat } from 'shared/constants/dateFormats';
// import { dateTimeFormat, timeFormat } from 'shared/constants/dateFormats';

export const handleFormatting = (time, formatString) => {
  if (!time) return '';
  let t = time;
  if (typeof time === 'string') {
    t = parseISO(time);
  }
  return format(t, formatString);
};

export const cleanTime = time => {
  if (typeof time === 'string') {
    return parseISO(time);
  }
  return time;
};

export const formatDate = time => handleFormatting(time, dateFormat);
export const formatDateShort = time => handleFormatting(time, dateShortFormat);
export const formatIsoDate = time => {
  if (time) {
    try {
      return formatISO(cleanTime(time), { representation: 'date' });
    } catch (RangeError) {
      return null;
    }
  }
  return null;
};
// export const formatTime = time => handleFormatting(time, timeFormat);
// export const formatDateTime = time => handleFormatting(time, dateTimeFormat);
