export default {
  '& .MuiInputLabel-filled': {
    transform: 'translate(12px, 18px) scale(1)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(12px, 10px) scale(1)',
    },
  },
  '& .MuiFilledInput-input': {
    paddingTop: '30px',
    paddingBottom: '6px',
  },
};
