export default [
  {
    key: 'regular',
    value: 'Regular',
  },
  {
    key: 'paymentplan',
    value: 'Payment Plan',
  },
];
