import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import typography from 'theme/typography';

const preceptorTitleStyles = makeStyles(theme => createStyles({
  preceptorTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  id: {
    ...typography.subtitle3,
    color: theme.palette.text.secondary,
  },
  idText: {
    ...typography.subtitle3,
  },
  statusCanceled: {
    opacity: 0.6,
    filter: 'saturate(0.1) grayscale(0.5)',
  },
}));

export default preceptorTitleStyles;
