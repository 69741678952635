import { gql } from '@apollo/client/core';

export const MY_PAYMENT_METHODS = gql`
  query myPaymentMethods {
    myPaymentMethods {
      uuid
      stripeUid
      brand
      last4
      expMonth
      expYear
      isDefault
      hasUpcomingPayments
      hasPendingPayments
    }
  }
`;

export const CREATE_SETUP_INTENT = gql`
  mutation createSetupIntent {
    createSetupIntent {
      status
      clientSecret
    }
  }
`;

export const CREATE_CARD = gql`
  mutation createCard($stripeUid: String!) {
    createCard(stripeUid: $stripeUid) {
      status
      message
    }
  }
`;

export const SET_DEFAULT_CARD = gql`
  mutation setDefaultCard($uuid: String!) {
    setDefaultCard(uuid: $uuid) {
      status
      message
    }
  }
`;

export const DELETE_CARD = gql`
  mutation deleteCard($uuid: String!) {
    deleteCard(uuid: $uuid) {
      status
      message
    }
  }
`;

export const CREATE_PAYMENT_INTENT = gql`
  mutation createPaymentIntent($uuid: String!, $description: String!, $addCard: Boolean!) {
    createPaymentIntent(uuid: $uuid, description: $description, addCard: $addCard) {
      status
      clientSecret
    }
  }
`;

export const PAY_ROTATION = gql`
  mutation payRotation($uuid: String!, $description: String!, $intentUid: String!, $stripeUid: String!, $addCard: Boolean!) {
    payRotation(uuid: $uuid, description: $description, intentUid: $intentUid, stripeUid: $stripeUid, addCard: $addCard) {
      status
      message
    }
  }
`;

export const SET_PROCESSING_DEPOSIT = gql`
  mutation setProcessingDeposit($rotationUuid: String!, $processingDeposit: Boolean!) {
    setProcessingDeposit(rotationUuid: $rotationUuid, processingDeposit: $processingDeposit) {
      status
      message
    }
  }
`;

export const CHANGE_ROTATION_STATUS = gql`
  mutation changeRotationStatus($uuid: String!, $description: String!) {
    changeRotationStatus(uuid: $uuid, description: $description) {
      status
      message
    }
  }
`;

export const MY_PAYMENTS = gql`
  query myPayments($rotationUuid: String) {
    myPayments(rotationUuid: $rotationUuid) {
      totalCount
      results {
        uuid
        totalAmount
        paymentAmount
        createdAt
        method {
          key
          value
        }
        description {
          key
          value
        }
        status {
          key
          value
        }
        rotation {
          uid
          uuid
        }
        preceptor {
          uid
          uuid
        }
        paymentMethod {
          brand
          last4
        }
      }
    }
  }
`;
