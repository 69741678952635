import React from 'react';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import refundPolicyLinkStyles from './styles';

const RefundPolicyLink = () => {
  const classes = refundPolicyLinkStyles();

  return (
    <Box>
      <Link
        href="https://www.preceptorlink.com/cancellation-and-refund-policy.html"
        target="_blank"
        className={classes.link}
      >
        Refund policy
      </Link>
    </Box>
  );
};

export default RefundPolicyLink;
