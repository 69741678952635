import palette from '../palette';

export default {
  root: {
    color: palette.icon,
    '&:hover': {
      color: palette.grey[400],
    },
    '&$disabled': {
      color: palette.icon,
    },
  },
  colorPrimary: {
    '&$checked': {
      color: palette.secondary.main,
    },
  },
};
