import {
  OPEN_SIGN_IN,
  OPEN_SIGN_UP,
  OPEN_FORGOT_PASSWORD,
  OPEN_SELECT_ROLE,
  CLOSE_ALL,
} from 'actions/authDialogConstants';

export const openSignIn = () => ({ type: OPEN_SIGN_IN });
export const openSignUp = () => ({ type: OPEN_SIGN_UP });
export const openForgotPassword = () => ({ type: OPEN_FORGOT_PASSWORD });
export const openSelectRole = () => ({ type: OPEN_SELECT_ROLE });
export const closeAll = () => ({ type: CLOSE_ALL });
