import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import buttonProgresStyles from './styles';

const ButtonProgress = ({ isLoading, size }) => {
  const classes = buttonProgresStyles();
  if (isLoading) return <CircularProgress size={size} className={classes.buttonProgress} />;

  return null;
};

ButtonProgress.defaultProps = {
  isLoading: false,
  size: 24,
};

ButtonProgress.propTypes = {
  isLoading: PropTypes.bool,
  size: PropTypes.number,
};

export default ButtonProgress;
