import React, { useMemo } from 'react';
import { AuthProvider, useFirebaseApp } from 'reactfire';
import { getAuth } from 'firebase/auth';
import PropTypes from 'prop-types';

const FirebaseAuthProvider = ({ children }) => {
  const app = useFirebaseApp();
  const auth = useMemo(() => getAuth(app), [app]);
  return (
    <AuthProvider sdk={auth}>
      {children}
    </AuthProvider>
  );
};

FirebaseAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FirebaseAuthProvider;
