// import palette from '../palette';

export default {
  root: {
    '&:hover': {
      '& .MuiInputLabel-filled:not(.Mui-disabled)': {
        // color: palette.text.secondary,
      },
    },
  },
};
