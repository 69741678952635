import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    [theme.breakpoints.down('md')]: {
      height: 'calc (100vh - 80px)',
    },
  },
  content: {
    flexGrow: 1,
  },
  withEmailVerificationBanner: {
    paddingTop: 0,
  },
}));
