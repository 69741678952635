import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Box, Divider } from '@material-ui/core';
import UserMenu from 'shared/components/UserMenu';
import { useBackendUserState } from 'contexts/UserContext';
import { useSigninCheck } from 'reactfire';
// import Search from 'shared/components/SearchPublicHeader/partials/Search';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { openSignIn, openSignUp } from 'actions/authDialogActions';
import Container from '@material-ui/core/Container';
// import ExpandedSearch from './partials/ExpandedSearch';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// import NotificationSystem from 'shared/components/NotificationSystem';
import paths from 'paths';
import userRoles from 'apps/account/constants/userRoles';
import searchPublicHeaderStyles from './styles';
import UserTabs from '../UserTabs';

const SearchPublicHeader = ({ maxWidth }) => {
  const classes = searchPublicHeaderStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const { data: signInCheckResult } = useSigninCheck();
  const { state: { user: backendUser } } = useBackendUserState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // TODO: we have to change this behaviour at some moment.
  // const [isSearchExpanded, setIsSearchExpanded] = useState(true);

  // const handleExpandSearch = () => {
  //   setIsSearchExpanded(true);
  // };

  const handleOpenSignIn = () => dispatch(openSignIn());

  const handleOpenSignUp = () => dispatch(openSignUp());

  const isUserAuthenticated = signInCheckResult.signedIn;

  const removeTrailingSlash = path => path.replace(/\/$/, '');

  const signInPagePath = removeTrailingSlash(paths.accountPaths.signIn.reverse());
  const signUpPagePath = removeTrailingSlash(paths.accountPaths.signUp.reverse());
  const termsPagePath = removeTrailingSlash(paths.commonPaths.termsAndConditions.reverse());
  const privacyPolicyPath = removeTrailingSlash(paths.commonPaths.privacyPolicy.reverse());
  const hideButtons = [
    signInPagePath, signUpPagePath, termsPagePath, privacyPolicyPath,
  ].includes(removeTrailingSlash(location.pathname));

  let logoURL = '#';
  if (isUserAuthenticated) {
    if (backendUser?.wizardState?.isWizardCompleted) {
      logoURL = backendUser?.lastUsedRole?.key === userRoles.preceptor
        ? paths.rotationPaths.all.reverse() : paths.searchPublicPaths.publicSearch.reverse();
    }
  } else {
    logoURL = paths.searchPublicPaths.publicSearch.reverse();
  }

  return (
    <AppBar
      className={classes.appBar}
      position="fixed"
      id="menu"
      elevation={0}
    >
      <Container maxWidth={maxWidth}>
        <Toolbar disableGutters className={classes.toolbar}>
          <Box display="inline-flex" alignItems="center">
            <Link href={logoURL}>
              <img
                src={isMobile
                  ? 'https://firebasestorage.googleapis.com/v0/b/preceptor-link.appspot.com/o/LogoWhite%2Flogo-mini-green.svg?alt=media&token=02bd49e2-3cff-4a94-b76c-85591bf6df78'
                  : 'https://firebasestorage.googleapis.com/v0/b/preceptor-link.appspot.com/o/LogoWhite%2Flogo-green.svg?alt=media&token=1e42ce43-58a4-4d47-937c-c92ee4ace385'}
                alt="logo"
                className={classes.logo}
              />
            </Link>
            {!isMobile && !isUserAuthenticated && (
              <Box ml={3} display="inline-flex">
                <Link
                  className={classes.brandingMainGradient}
                  href="mailto:info@preceptorlink.com"
                >
                  info@preceptorlink.com
                </Link>
                <Divider orientation="vertical" className={classes.verticalDivider} />
                <Link
                  className={classes.brandingMainGradient}
                  href="tel:+17606040913"
                >
                  760-604-0913
                </Link>
              </Box>
            )}
          </Box>
          {isUserAuthenticated && !isMobile && (
            <UserTabs />
          )}
          <Box display="inline-flex" alignItems="center">
            {isUserAuthenticated ? (
              <Box display="flex" alignItems="baseline">
                {/* <NotificationSystem /> */}
                <UserMenu />
              </Box>
            ) : (
              <>
                {!isMobile && !isUserAuthenticated && (
                  <Box mr={!hideButtons && 3} display="inline-flex">
                    <Link
                      className={classes.brandingMainGradient}
                      target="_blank"
                      href="https://www.preceptorlink.com/how-it-works.html"
                    >
                      How it works
                    </Link>
                    <Divider orientation="vertical" className={classes.verticalDivider} />
                    <Link
                      className={classes.brandingMainGradient}
                      href="/account/sign-up/"
                    >
                      Preceptor sign-up
                    </Link>
                  </Box>
                )}
                {!hideButtons && (
                  <>
                    <Box mr={1}>
                      <Button onClick={handleOpenSignIn} className={classes.buttonLogin} variant="outlined">
                        Login
                      </Button>
                    </Box>
                    <Box>
                      <Button onClick={handleOpenSignUp} className={classes.buttonSignUp} variant="contained">
                        Sign Up
                      </Button>
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

SearchPublicHeader.defaultProps = {
  maxWidth: false,
};

SearchPublicHeader.propTypes = {
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default SearchPublicHeader;
