import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import typography from 'theme/typography';

const rotationInfoItemStyles = makeStyles(() => createStyles({
  label: {
    ...typography.subtitle3,
    color: '#AFAEAE',
    whiteSpace: 'nowrap',
  },
}));

export default rotationInfoItemStyles;
