import React from 'react';
import { LinearProgress } from '@material-ui/core';

const LinearProgressCustom = () => (
  <LinearProgress style={{
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 9999999,
  }}
  />
);

export default LinearProgressCustom;
