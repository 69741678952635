import React, { useState } from 'react';
import { confirmPasswordReset } from 'firebase/auth';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box, Button, Typography, FormHelperText, TextField,
} from '@material-ui/core';

import { useAuth } from 'reactfire';
import { useHistory } from 'react-router-dom';
import useQueryString from 'shared/hooks/useQueryString';
import newPasswordStyles from './styles';

const schema = yup.object().shape({
  password1: yup.string()
    .required('Field is required')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  password2: yup.string()
    .required('Field is required')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .oneOf([yup.ref('password1')], 'Your password and confirmation password do not match'),
});

const NewPassword = () => {
  const classes = newPasswordStyles();
  const auth = useAuth();
  const history = useHistory();
  const [submitErrors, setSubmitErrors] = useState('');
  const { urlParams } = useQueryString();

  const {
    register, handleSubmit, formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const onSubmit = data => {
    confirmPasswordReset(auth, urlParams.oobCode, data.password1).then(() => {
      history.push('/');
    }).catch(error => {
      setSubmitErrors(error.message);
    });
  };

  return (
    <>
      <Box className={classes.logo}>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/preceptor-link.appspot.com/o/LogoWhite%2Flogo-green.svg?alt=media&token=1e42ce43-58a4-4d47-937c-c92ee4ace385"
          alt="logo"
        />
      </Box>
      <Box className={classes.form}>
        <Typography variant="h5">
          New Password
        </Typography>
        <Box className={classes.text} mb={2} maxWidth="430px">
          <Typography variant="subtitle2">
            Type your new password 2
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={1}>
            <TextField
              label="Password"
              type="password"
              fullWidth
              variant="filled"
              name="password1"
              {...register('password1')}
              error={Boolean(errors.password1?.message)}
              helperText={errors.password1?.message}
            />
          </Box>
          <TextField
            label="Re-type password"
            type="password"
            fullWidth
            variant="filled"
            name="password2"
            {...register('password2')}
            error={Boolean(errors.password2?.message)}
            helperText={errors.password2?.message}
          />
          {submitErrors && (
            <Box mb={1}>
              <FormHelperText error>
                {submitErrors}
              </FormHelperText>
            </Box>
          )}
          <Box mt={2}>
            <Button variant="contained" size="large" fullWidth type="submit">
              Reset My Password
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default NewPassword;
