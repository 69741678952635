import palette from '../palette';
import typography from '../typography';

export default {
  root: {
    ...typography.caption,
    borderBottom: `1px solid ${palette.divider}`,
    paddingRight: 4,
    paddingLeft: 4,
    paddingTop: 11,
    paddingBottom: 8,
  },
  head: {
    ...typography.caption,
    color: palette.primary.main,
    padding: 4,
    borderBottom: '1px solid transparent',
    '&:first-child': {
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
    },
    '&:last-child': {
      borderTopRightRadius: 12,
      borderBottomRightRadius: 12,
    },
  },
};
