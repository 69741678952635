import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Menu, MenuItem } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import searchTagHiddenStyles from './styles';

const ITEM_HEIGHT = 48;

const SearchTagHidden = ({
  specialty, limit, inline,
}) => {
  const classes = searchTagHiddenStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      className={classes.chips}
      style={{ display: inline ? 'inline-flex' : 'block', verticalAlign: inline ? 'baseline' : 'top' }}
    >
      {specialty.slice(0, limit).map(el => <Chip className={classes.chip} key={el.value} variant="outlined" size="small" label={el.value} />)}
      <Box display="inline-flex" onClick={handleClick} style={{ cursor: 'pointer' }}>
        {specialty.length > limit && (
          <Typography className={classes.tag}>
            +
            {specialty.length - limit}
          </Typography>
        )}
      </Box>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {specialty.slice(4).map(option => (
          <MenuItem key={option} onClick={handleClose}>
            {option.value}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

SearchTagHidden.defaultProps = {
  limit: 4,
  inline: false,
};

SearchTagHidden.propTypes = {
  specialty: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })).isRequired,
  limit: PropTypes.number,
  inline: PropTypes.bool,
};

export default SearchTagHidden;
