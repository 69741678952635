import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const RadioButton = () => (
  <Box mb={4} mt={4}>
    <Typography variant="h5" align="center" gutterBottom>
      Radiobutton
    </Typography>
    <Grid container justify="center" alignItems="baseline" spacing={2}>
      <Grid item display="flex" alignItems="center">
        <FormControlLabel
          control={<Radio />}
          label="Radio-Button"
        />
      </Grid>
      <Grid item>
        <Radio
          checked
        />
      </Grid>
      <Grid item>
        <Radio
          checked
          disabled
        />
      </Grid>
      <Grid item display="flex" alignItems="center">
        <FormControlLabel
          control={<Radio />}
          label="Radio-Button"
          disabled
        />
      </Grid>
    </Grid>
  </Box>
);

export default RadioButton;
