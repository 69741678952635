import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Box } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import headerCustomSearchStyles from './styles';

const HeaderCustomSearch = ({ maxWidth }) => {
  const classes = headerCustomSearchStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <AppBar
      className={classes.appBar}
      position="fixed"
      id="menu"
      elevation={0}
    >
      <Container maxWidth={maxWidth}>
        <Toolbar
          disableGutters
        >
          <Box>
            <Link href="/">
              <img
                src={isMobile
                  ? 'https://firebasestorage.googleapis.com/v0/b/preceptor-link.appspot.com/o/LogoWhite%2Flogo-mini-green.svg?alt=media&token=02bd49e2-3cff-4a94-b76c-85591bf6df78'
                  : 'https://firebasestorage.googleapis.com/v0/b/preceptor-link.appspot.com/o/LogoWhite%2Flogo-green.svg?alt=media&token=1e42ce43-58a4-4d47-937c-c92ee4ace385'}
                alt="logo"
                className={classes.logo}
              />
            </Link>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

HeaderCustomSearch.defaultProps = {
  maxWidth: false,
};

HeaderCustomSearch.propTypes = {
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default HeaderCustomSearch;
