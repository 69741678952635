import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import typography from '../../../theme/typography';

const blockSearchRecommendationsStyles = makeStyles(theme => createStyles({
  block: {
    border: `1px solid ${theme.palette.grey['300']}`,
    boxShadow: '0px 2px 4px rgba(23, 23, 23, 0.08)',
    borderRadius: '20px',
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '560px',
    width: '100%',
  },
  iconClose: {
    color: theme.palette.icon,
  },
  text: {
    ...typography.subtitle3,
    marginTop: 8,
    marginBottom: 9,
  },
  textSearch: {
    marginRight: 5,
  },
  divider: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  blockSearch: {
    margin: '5px 0',
  },
  button: {
    marginTop: 5,
  },
}));

export default blockSearchRecommendationsStyles;
