import React from 'react';
import { useBackendUserState } from '../../contexts/UserContext';

const Account = () => {
  const { state } = useBackendUserState();
  const { user } = state;

  return (
    <div>
      {user && user.name ? user.name : 'No Name'}
    </div>
  );
};

export default Account;
