import palette from '../palette';
import typography from '../typography';

export default {
  root: {
    ...typography.subtitle2,
    '&:active': {
      color: palette.primary.dark,
      underline: palette.primary.dark,
    },
  },
  underlineHover: {
    color: palette.primary.main,
  },
};
