export default {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    padding: '32px 35px 0',
    '& $button': {
      transform: 'translate(2px, 4px)',
      padding: '4px',
    },
  },
};
