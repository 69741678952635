import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import palette from '../../../../theme/palette';

const styles = theme => ({
  root: {
    border: `1px solid ${theme.palette.grey['300']}`,
    color: theme.palette.primary.main,
    background: theme.palette.white,
    borderRadius: '50%',
    padding: '9px',
    '&:hover': {
      border: `1px solid ${palette.primary.main}`,
      background: palette.white,
    },
    '&:active': {
      border: `1px solid ${palette.primary.dark}`,
      background: palette.white,
      color: palette.primary.dark,
    },
    '&:disabled': {
      border: `1px solid ${palette.grey['100']}`,
      color: palette.grey['400'],
      backgroundColor: palette.grey['100'],
    },
  },
});

export default withStyles(styles)(IconButton);
