import React from 'react';
import { useHistory } from 'react-router-dom';

import { useBackendUserState } from 'contexts/UserContext';
import { Tabs, Tab } from '@material-ui/core';
import paths from 'paths';
import { useLocation } from 'react-router';
import useWizardDecisionAndRedirect from 'apps/wizard/hooks/useWizardDecisionAndRedirect';
import userTabsStyles from './styles';

const UserTabs = () => {
  const classes = userTabsStyles();
  const history = useHistory();

  const { state: { user: backendUser } } = useBackendUserState();

  const wizardNotCompleted = !backendUser?.wizardState?.isWizardCompleted;

  useWizardDecisionAndRedirect();

  const handleTabClick = url => () => {
    history.push(url);
  };

  const location = useLocation();
  const getActiveTab = () => {
    if (location.pathname === '/') return 'home';
    if (location.pathname === '/favorites/') return 'favorites';
    if (location.pathname.includes('/rotations/')) return 'rotations';
    if (location.pathname.includes(`/${backendUser?.lastUsedRole?.key}/profile/`)) return 'profile';
    return 'none'; // workaround because 'false' should have deselected all tabs, but it doesn't
  };

  const [activeTab, setActiveTab] = React.useState(getActiveTab());

  if (wizardNotCompleted) {
    return (
      <></>
    );
  }

  return (
    <Tabs
      className={classes.tabs}
      value={activeTab}
      onChange={(event, newValue) => setActiveTab(newValue)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 0,
        horizontal: 'center',
      }}
    >
      {backendUser?.lastUsedRole?.key === 'student' && (
        <Tab
          key="home"
          className={classes.tab}
          value="home"
          label="Home"
          onClick={handleTabClick(paths.searchPublicPaths.publicSearch.reverse())}
        />
      )}
      <Tab
        key="rotations"
        className={classes.tab}
        value="rotations"
        label="My Rotations"
        onClick={handleTabClick(paths.rotationPaths.all.reverse())}
      />
      <Tab
        key="profile"
        className={classes.tab}
        value="profile"
        label="My Profile"
        onClick={handleTabClick(`/${backendUser.lastUsedRole?.key}/profile/`)}
      />
      {backendUser?.lastUsedRole?.key === 'student' && (
        <Tab
          key="favorites"
          className={classes.tab}
          value="favorites"
          label="Favorites"
          onClick={handleTabClick(paths.favoritesPaths.allFavoritePreceptors.reverse())}
        />
      )}
      <Tab
        className={classes.invisibleTab}
        value="none"
        label=""
        disabled
      />
    </Tabs>
  );
};

export default UserTabs;
