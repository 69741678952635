import React from 'react';
import Box from '@material-ui/core/Box';
import { Button, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Link as ScrollLink } from 'react-scroll';
import stressFreeBlockStyles from './styles';

const StressFreeBlock = () => {
  const classes = stressFreeBlockStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box>
      <Box textAlign="center">
        <Typography
          variant={isMobile ? 'h5' : 'h3'}
        >
          Stress-Free Cancellation Policy
        </Typography>
      </Box>
      <Box
        textAlign="center"
        mt={2.5}
        mb={4}
      >
        <Typography
          variant={isMobile ? 'subtitle2' : 'subtitle1'}
          color="textSecondary"
        >
          If you cancel the rotation&nbsp;
          <span className={classes.textBold}>before</span>
          &nbsp;PreceptorLink has found
          a preceptor you are eligible for a refund.
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className={classes.greenBlock}>
            <Typography
              variant={isMobile ? 'subtitle2' : 'h5'}
            >
              Preceptor Cancellation
            </Typography>
            <Box mt={2}>
              <Typography variant="span" color="textSecondary" style={{ fontWeight: 'bold' }}>
                Preceptor cancels rotation:
                {' '}
              </Typography>
              <Typography variant="span" color="textSecondary">
                The PreceptorLink® Team will find a replacement preceptor. If the PreceptorLink®
                Team is unable to find a replacement, you will have the option to:
              </Typography>
            </Box>
            <List>
              <ListItem className={classes.listItem}>
                Roll the rotation forward to when the preceptor is available
              </ListItem>
              <ListItem className={classes.listItem}>Receive a refund*</ListItem>
            </List>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={classes.blueBlock}>
            <Typography variant={isMobile ? 'subtitle2' : 'h5'}>
              Student Cancellation
            </Typography>
            <Box mt={2}>
              <Typography variant="span" color="textSecondary" style={{ fontWeight: 'bold' }}>
                Before the preceptor is found:
                {' '}
              </Typography>
              <Typography variant="span" color="textSecondary">
                The $150 non-refundable applies.
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography color="textSecondary" style={{ fontWeight: 'bold' }}>
                The preceptor is accepted by student, but the preceptor agreement has not been
                signed (not affiliation agreement/MOU):
              </Typography>
            </Box>
            <List>
              <ListItem className={classes.listItem}>
                1-7 days from deposit: Refund = Deposit minus $250 and any non refundable fees*.
              </ListItem>
              <ListItem className={classes.listItem}>
                8+ days from deposit:  Refund = Deposit minus $500 and any non refundable fees*.
              </ListItem>
            </List>
            <Box mt={2}>
              <Typography variant="span" color="textSecondary" style={{ fontWeight: 'bold' }}>
                The preceptor is accepted by the student, and the preceptor agreement has been
                signed (not affiliation agreement/MOU):
                {' '}
              </Typography>
              <Typography variant="span" color="textSecondary">
                No refund applies.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box mt={4} mb={3}>
        <Box>
          <Typography
            variant={isMobile ? 'subtitle2' : 'subtitle1'}
            className={classes.hint}
          >
            * $150 Search Fee and 5% Application Fee are non-refundable
          </Typography>
        </Box>
      </Box>
      <Typography className={classes.link}>
        See also in detailed&nbsp;
        <Link
          target="_blank"
          className={classes.link}
          href="https://www.preceptorlink.com/Cancellation-and-Refund-Policy.html"
        >
          <span className={classes.linkBold}>Refund Policy</span>
        </Link>
      </Typography>
      <Box mt={3} mb={1}>
        <ScrollLink
          to="form"
          smooth
          duration={500}
          offset={-100}
        >
          <Button
            variant="contained"
            size="large"
          >
            Request Custom Search
          </Button>
        </ScrollLink>
      </Box>
    </Box>
  );
};

export default StressFreeBlock;
