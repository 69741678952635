import palette from '../palette';
import typography from '../typography';

export default {
  root: {
    color: palette.grey[800],
  },
  outlined: {
    border: `1px solid ${palette.colors.lightGreen}`,
    borderRadius: 8,
    '& > $label': {
      ...typography.subtitle3,
      color: palette.secondary.dark,
    },
  },
};
