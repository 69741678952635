import palette from '../palette';
import typography from '../typography';

export default {
  root: {
    border: `1px dashed ${palette.grey[400]}`,
    borderRadius: 15,
    minHeight: 120,
    backgroundColor: palette.grey[50],
    padding: 24,
  },
  text: {
    marginTop: 0,
    marginBottom: 0,
    ...typography.subtitle1,
    color: palette.text.hint,
  },
  icon: {
    color: palette.icon,
    height: 32,
    weight: 32,
  },
};

