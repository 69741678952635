import React, { Suspense } from 'react';
import LinearProgressCustom from 'shared/components/LinearProgressCustom';

// -----------------------|| LOADABLE - LAZY LOADING ||-----------------------//

const Loadable = Component => props => (
  <Suspense fallback={<LinearProgressCustom />}>
    <Component {...props} />
  </Suspense>
);

export default Loadable;
