import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => createStyles({
  root: {
    flexGrow: 1,
    background: theme.palette.background.paper,
    minHeight: '79px',
  },
  container: {
    padding: '30px 0',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: '15px 0',
    },
  },
  text: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      paddingLeft: 0,
    },
  },
  link: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
}));
