import palette from '../palette';
import typography from '../typography';

export default {
  root: {
    position: 'relative',
    textTransform: 'none',
    backgroundColor: palette.background.paper,
    minWidth: '148px !important',
    width: '148px !important',
    padding: '6px 0',
    ...typography.subtitle2,
    '&:before': {
      display: 'none',
      content: '" "',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: 3,
      backgroundColor: palette.grey[300],
      '&:hover': {
        color: palette.secondary.main,
      },
    },
    '& .MuiLink-root': {
      color: palette.text.primary,
    },
    '&$selected': {
      color: palette.secondary.main,
      '& .MuiLink-root': {
        color: palette.secondary.main,
      },
    },
    '&$disabled': {
      color: palette.text.disabled,
    },
    '&:hover': {
      '& .MuiLink-root': {
        color: palette.secondary.main,
      },
    },
    '&:focus': {
      color: palette.secondary.main,
    },
  },
  wrapper: {
    color: palette.text.primary,
  },
  textColorInherit: {
    opacity: 1,
  },
};
