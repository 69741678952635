import React from 'react';
import {
  Grid, Box, Container,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import publicFooterStyles from './styles';

const ContactsBlock = () => {
  const classes = publicFooterStyles();
  return (
    <Grid item xs={12} md={4}>
      <Box className={classes.link}>
        <Box mr={3}>
          <Typography variant="subtitle2">
            <Link
              href="mailto:info@preceptorlink.com"
            >
              info@preceptorlink.com
            </Link>
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2">
            <Link
              href="tel:+17606040913"
            >
              760-604-0913
            </Link>
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

const CopyrightBlock = () => {
  const classes = publicFooterStyles();
  return (
    <Grid item xs={12} md={8}>
      <Typography variant="subtitle2" color="textPrimary" className={classes.text}>
        © Copyright 2022 Preceptorlink, Inc. — All rights reserved.
      </Typography>
    </Grid>
  );
};

const TermsBlock = () => {
  const classes = publicFooterStyles();
  return (
    <Grid item xs={12} md={4}>
      <Box className={classes.link}>
        <Box mr={3}>
          <Typography variant="subtitle2">
            <Link
              href="https://www.preceptorlink.com/terms-and-conditions.html"
              target="_blank"
            >
              Terms and conditions
            </Link>
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2">
            <Link
              href="/privacy-policy"
              target="_blank"
            >
              Privacy
            </Link>
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

const PublicFooter = () => {
  const classes = publicFooterStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div className={classes.root}>
      <Container>
        <Grid container className={classes.container}>

          {isMobile ? (
            <>
              <ContactsBlock />
              <CopyrightBlock />
              <TermsBlock />
            </>
          ) : (
            <>
              <CopyrightBlock />
              <TermsBlock />
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default PublicFooter;
