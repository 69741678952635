import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '@material-ui/lab/Pagination';
import useQueryString from 'shared/hooks/useQueryString';

const SearchPagination = ({ itemsPerPage, totalCount, scrollToTop }) => {
  const { urlParams, setQueryString } = useQueryString();
  const pageCount = Math.ceil(totalCount / itemsPerPage);

  const intFirst = parseInt(urlParams.first || itemsPerPage, 10);
  const intSkip = parseInt(urlParams.skip || 0, 10);
  const paginationPage = intSkip === 0 ? 1 : Math.ceil(intSkip / intFirst + 1);

  const scrollTop = () => {
    if (window !== undefined) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleChange = (event, page) => {
    if (scrollToTop) scrollTop();

    const newParams = { first: String(intFirst), skip: String(page * intFirst - intFirst) };
    setQueryString({ ...urlParams, ...newParams });
  };

  return (
    <Pagination
      count={pageCount}
      onChange={handleChange}
      page={paginationPage}
      shape="rounded"
    />
  );
};

SearchPagination.defaultProps = {
  itemsPerPage: 10,
  totalCount: 0,
  scrollToTop: false,
};

SearchPagination.propTypes = {
  itemsPerPage: PropTypes.number,
  totalCount: PropTypes.number,
  scrollToTop: PropTypes.bool,
};

export default SearchPagination;
