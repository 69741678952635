import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => createStyles({
  appBar: {
    background: '#fff',
    flexGrow: 1,
    minHeight: 75,
    borderBottom: `1px solid ${theme.palette.grey['400']}`,
  },
  logo: {
    margin: '16px 0 16px 0',
  },
}));
