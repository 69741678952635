import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButtonStyled from '../IconButtonStyled';
import { ReactComponent as AddLocationIcon } from '../../../../../assets/icons/addLocation.svg';
import recommendationsStyles from './styles';

const Recommendations = ({ preceptorCount, rating, cost }) => {
  const classes = recommendationsStyles();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex">
        <Box className={classes.dividerRight}>
          <Typography variant="subtitle2" className={classes.text}>
            {preceptorCount}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" className={classes.textSearch}>
            Average Rating:
            {' '}
            {rating}
          </Typography>
        </Box>
        <Box className={classes.dividerLeft}>
          <Typography variant="subtitle2" className={classes.textSearch}>
            Average Cost: $
            {cost}
          </Typography>
        </Box>
      </Box>
      <Box>
        <IconButtonStyled>
          <AddLocationIcon />
        </IconButtonStyled>
      </Box>
    </Box>
  );
};

Recommendations.propTypes = {
  preceptorCount: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  cost: PropTypes.number.isRequired,
};

export default Recommendations;
