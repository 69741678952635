import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import availableStyles from './styles';

const Available = ({ hours, rightRectangle }) => {
  const classes = availableStyles();
  const title = `Available (${hours || 0} hrs)`;

  return (
    <Box className={clsx(classes.block, {
      [classes.right]: rightRectangle,
    })}
    >
      <CheckIcon fontSize="small" className={classes.iconCheck} />
      <Typography variant="subtitle2" className={classes.available}>
        {title}
      </Typography>
    </Box>
  );
};

Available.defaultProps = {
  hours: '',
  rightRectangle: false,
};

Available.propTypes = {
  hours: PropTypes.number,
  rightRectangle: PropTypes.bool,
};

export default Available;
