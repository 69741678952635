import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import typography from 'theme/typography';

const rotationTagHiddenStyles = makeStyles(theme => createStyles({
  chip: {
    marginTop: theme.spacing(0.5),
    // borderColor: theme.palette.colors.lightPurple,
    border: `1px solid ${theme.palette.colors.lightPurple}`,
    marginRight: theme.spacing(1),
    '& .MuiChip-label': {
      color: theme.palette.colors.purple,
      maxWidth: '100%',
    },
  },
  tag: {
    ...typography.subtitle3,
    marginTop: theme.spacing(0.5),
    background: theme.palette.colors.lightPurpleBackground,
    padding: '1px 8px',
    color: theme.palette.colors.purple,
    borderRadius: 8,
    display: 'inline-flex',
  },
}));

export default rotationTagHiddenStyles;
