import React from 'react';
import PropTypes from 'prop-types';
import {
  ApolloClient, InMemoryCache, from, ApolloLink, ApolloProvider,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { useAuth } from 'reactfire';
import * as Sentry from '@sentry/react';
import { setContext } from '@apollo/client/link/context';

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    const criticalErrors = [
      'Error decoding signature',
      'Signature has expired',
      'User is disabled',
      'Unauthorized',
    ];
    graphQLErrors.forEach(({ message }) => {
      if (criticalErrors.includes(message)) {
        window.localStorage.clear();
        const locationParams = `${window.location.pathname}${window.location.search}`;
        if (!window.location.href.includes('/account/sign-in/')) {
          window.location.href = `/account/sign-in/?next=${locationParams}`;
        }
      }
    });
  }
});

const sentryResponseLoggerLink = new ApolloLink((operation, forward) => (
  forward(operation).map(response => {
    // Capture the response as a breadcrumb in Sentry because it is not captured in network tab
    // due to s bug either in apollo client or sentry
    Sentry.addBreadcrumb({
      category: 'graphql',
      message: `GraphQL ${operation.operationName} Response`,
      data: {
        response: response.data,
        operationName: operation.operationName,
        variables: operation.variables,
      },
    });
    return response;
  })
));

const uploadLink = createUploadLink({ uri: process.env.REACT_APP_APOLLO_CLIENT_URI });

const AuthApolloProvider = ({ children }) => {
  const auth = useAuth();

  const client = new ApolloClient({
    link: from([
      setContext(async (_, { headers }) => {
        if (!auth.currentUser) {
          return { headers };
        }
        const authToken = await auth.currentUser?.getIdToken();
        return {
          headers: {
            ...headers,
            authorization: authToken,
          },
        };
      }),
      sentryResponseLoggerLink,
      errorLink,
      uploadLink,
    ]),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};

AuthApolloProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthApolloProvider;
