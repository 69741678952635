import React from 'react';
import { Box, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import notAvailableStyles from './styles';

const NotAvailable = ({ rightRectangle }) => {
  const classes = notAvailableStyles();

  return (
    <Box className={clsx(classes.block, {
      [classes.right]: rightRectangle,
    })}
    >
      <CancelIcon fontSize="small" className={classes.iconCancel} />
      <Typography variant="subtitle2" className={classes.notAvailable}>
        Not available
      </Typography>
    </Box>
  );
};

NotAvailable.defaultProps = {
  rightRectangle: false,
};

NotAvailable.propTypes = {
  rightRectangle: PropTypes.bool,
};

export default NotAvailable;
