import palette from '../palette';

export default {
  root: {
    '&::before': {
      display: 'none',
    },
    '&::after': {
      display: 'none',
    },
    backgroundColor: palette.background.paper,
    '&:hover': {
      backgroundColor: palette.background.paper,
      borderColor: palette.grey[400],
      filter: 'drop-shadow(0px 3px 4px rgba(233, 242, 242, 0.32))',
      '& $input': {
        borderColor: palette.grey[400],
      },
    },
    '&$marginDense': {
      borderRadius: 8,
    },
    '&$focused': {
      borderColor: palette.secondary.main,
      backgroundColor: palette.background.paper,
    },
    '&$error': {
      background: palette.background.paper,
      border: `1px solid ${palette.error.main}`,
      boxShadow: '0px 2px 4px rgba(246, 68, 68, 0.08)',
      filter: 'none',
    },
    '&$disabled': {
      filter: 'none',
      pointerEvents: 'none',
      backgroundColor: palette.grey[100],
      color: palette.grey[500],
      '& > .MuiInputAdornment-root .MuiTypography-root': {
        color: palette.grey[500],
      },
    },
  },
  input: {
    fontWeight: 400,
    fontSize: '0.875rem',
    letterSpacing: '0.011em',
    lineHeight: 1.43,
    borderRadius: 8,
    background: 'transparent',
    paddingTop: '23px',
    paddingBottom: '6px',
    color: palette.text.primary,
    filter: 'drop-shadow(0px 3px 4px rgba(233, 242, 242, 0.32))',
    '&$disabled': {
      pointerEvents: 'none',
    },
    '&$inputMarginDense': {
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: 'inherit',
    },
  },
  inputMultiline: {
    resize: 'vertical',
  },
};
