import palette from '../palette';

export default {
  rangeIntervalDayHighlight: {
    backgroundColor: palette.secondary.main,
  },
  rangeIntervalDayPreviewStart: {
    '&$selected': {
      borderRadius: '10%',
    },
  },
  dayOutsideRangeInterval: {
    '&:hover': {
      // border: `1px solid ${palette.grey[500]}`,
      background: palette.colors.lightGreenHover,
    },
  },
  dayInsideRangeInterval: {
    color: palette.background.paper,
    backgroundColor: '#46A480 !important',
  },
};
