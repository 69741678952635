import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const rightBlockCardStyles = makeStyles(theme => createStyles({
  icon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1),
  },
}));

export default rightBlockCardStyles;
