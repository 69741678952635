import palette from '../palette';
import typography from '../typography';

export default {
  root: {
    color: palette.grey['300'],
    '&$disabled': {
      color: palette.divider,
    },
    '&$disabled $track': {
      color: palette.text.disabled,
    },
    '&$disabled $thumb': {
      color: palette.text.disabled,
      height: 12,
      width: 12,
      borderRadius: '50%',
      marginTop: '-5px',
    },
  },
  rail: {
    color: palette.divider,
    opacity: 1,
  },
  track: {
    color: palette.secondary.main,
  },
  markLabelActive: {
    ...typography.subtitle2,
    color: palette.text.hint,
  },
  markLabel: {
    ...typography.subtitle2,
    color: palette.text.hint,
  },
  valueLabel: {
    color: palette.secondary.main,
  },
  thumb: {
    color: palette.secondary.main,
  },
};
