import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

export default withStyles(() => ({
  root: {
    '& .MuiFilledInput-root': {
      paddingBottom: 0,
      paddingTop: 17,
      paddingLeft: 11,
    },
  },
}))(TextField);
