import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const listOfPreceptorsStyles = makeStyles(theme => createStyles({
  paper: {
    display: 'flex',
    maxWidth: '520px',
    width: '100%',
    height: '400px',
    borderRadius: 16,
    background: theme.palette.background.paper,
    overflowY: 'scroll',
  },
  iconClose: {
    color: theme.palette.icon,
  },
}));

export default listOfPreceptorsStyles;
