import { useHistory } from 'react-router';
import { useSigninCheck } from 'reactfire';
import PropTypes from 'prop-types';
import paths from 'paths';

const AuthGuard = ({ children }) => {
  const history = useHistory();
  const { location } = history;
  const { data: { signedIn } } = useSigninCheck();

  if (!signedIn && location.pathname !== paths.accountPaths.signIn.reverse()) {
    // Using window.location so UserProvider does not invoke sign up dialog opening before redirecting to sign in
    window.location.href = `${paths.accountPaths.signIn.reverse()}?next=${encodeURIComponent(location.pathname + location.search)}`;
  }

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
