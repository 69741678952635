import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import HeaderCustomSearch from 'shared/components/HeaderCustomSearch';
import customSearchLayoutStyles from './styles';
import PublicFooter from '../../shared/components/PublicFooter';

const CustomSearchLayout = ({ children }) => {
  const classes = customSearchLayoutStyles();

  return (
    <Box className={classes.root}>
      <HeaderCustomSearch maxWidth="lg" />
      <Box
        className={classes.content}
        display="flex"
        flexDirection="column"
      >
        {children}
      </Box>
      <Divider />
      <PublicFooter />
    </Box>
  );
};

CustomSearchLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomSearchLayout;
