import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import typography from 'theme/typography';
import palette from 'theme/palette';

const firstBlockStyles = makeStyles(theme => createStyles({
  tradeMark: {
    ...typography.h5,
  },
  text: {
    marginBottom: theme.spacing(1),
    maxWidth: 580,
  },
  textGrey: {
    maxWidth: 510,
  },
  link: {
    color: theme.palette.secondary.main,
    '&:active': {
      color: palette.secondary.dark,
      underline: palette.secondary.dark,
    },
    position: 'relative',
    zIndex: 15,
  },
  textLink: {
    color: theme.palette.secondary.main,
    fontSize: '15px',
    lineHeight: '18px',
    fontWeight: 500,
  },
  icon: {
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(2),
    fontSize: 'small',
  },
  image: {
    maxWidth: '100%',
    marginTop: '-77px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
      marginTop: '-30px',
    },
  },
}));

export default firstBlockStyles;
