import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import searchRecommendationsStyles from './styles';

const SearchRecommendations = () => {
  const classes = searchRecommendationsStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      className={classes.block}
      flexDirection={isMobile ? 'column' : 'row'}
    >
      <Box
        display="flex"
        justifyContent="center"
        mr={isMobile ? 0 : 3}
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/preceptor-link.appspot.com/o/Images%2Fsearch-recommendations.png?alt=media&token=07842ecb-e175-4188-b1aa-f3cb91d96095"
          alt="not-found"
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Typography variant="h6">
            Search Recommendations
          </Typography>
          <CloseIcon className={classes.iconClose} />
        </Box>
        <Typography className={classes.text}>
          There are 23 great preceptors just 20 Mi outside of your search radius!
          <br />
          Consider expanding your search area to get better results.
        </Typography>
        <Box className={classes.button}>
          <Button
            variant="contained"
            size="medium"
            fullWidth
          >
            Expand My Search Area
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SearchRecommendations;
