import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@material-ui/core';
import circularProgressCustomStyles from './styles';

const CircularProgressCustom = ({ height, size }) => {
  const classes = circularProgressCustomStyles();

  return (
    <Box className={classes.root} height={height}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={size}
        thickness={4}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{ circle: classes.circle }}
        size={size}
        thickness={4}
      />
    </Box>
  );
};

CircularProgressCustom.defaultProps = {
  height: '',
  size: 64,
};

CircularProgressCustom.propTypes = {
  height: PropTypes.string,
  size: PropTypes.number,
};

export default CircularProgressCustom;
