import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CancelIcon from '@material-ui/icons/Cancel';
import canceledByPreceptorStyles from './styles';

const CanceledByPreceptor = () => {
  const classes = canceledByPreceptorStyles();

  return (
    <Box className={classes.block}>
      <CancelIcon fontSize="small" className={classes.iconCancel} />
      <Typography variant="subtitle2" className={classes.cancelByPreceptor}>
        Canceled
      </Typography>
    </Box>
  );
};

export default CanceledByPreceptor;
