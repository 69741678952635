import React from 'react';
import PropTypes from 'prop-types';
import useQueryString from 'shared/hooks/useQueryString';
import { Box, Grid } from '@material-ui/core';
import PreceptorTitle from 'shared/components/PreceptorCard/partials/PreceptorTitle';
import SpecialtyBlock from 'shared/components/PreceptorCard/partials/SpecialtyBlock';
import RightBlockCard from 'shared/components/PreceptorCard/partials/RightBlockCard';
import 'react-custom-scroll/dist/customScroll.css';
import bigPreceptorCardStyles from './styles';

const BigPreceptorCard = ({
  accountUID, generalLocation, typeOfPractice,
  availability, specialty, averageNumberOfPatientsPerDay,
  credentialsString, handleClick,
}) => {
  const classes = bigPreceptorCardStyles();
  const { urlParams: { dateFrom, dateTo, hours } = {} } = useQueryString();

  return (
    <Box className={classes.block} onClick={handleClick}>
      <Grid container>
        <Grid item xs={12}>
          <PreceptorTitle
            accountUID={accountUID}
            hours={(dateFrom && dateTo && hours) ? hours : undefined}
            availability={availability}
          />
        </Grid>
        <Grid item xs={12} md={8} className={classes.grid}>
          <Grid container className={classes.divider}>
            <Grid item xs={12} md={9} className={classes.grid}>
              <SpecialtyBlock credentialsString={credentialsString} specialty={specialty} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <RightBlockCard
            averageNumberOfPatientsPerDay={averageNumberOfPatientsPerDay}
            typeOfPractice={typeOfPractice}
            generalLocation={generalLocation}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

BigPreceptorCard.defaultProps = {
  accountUID: '',
  generalLocation: '',
  typeOfPractice: [],
  availability: '',
  specialty: [],
  averageNumberOfPatientsPerDay: null,
  credentialsString: '',
};

BigPreceptorCard.propTypes = {
  accountUID: PropTypes.string,
  generalLocation: PropTypes.string,
  typeOfPractice: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })),
  availability: PropTypes.number,
  specialty: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })),
  averageNumberOfPatientsPerDay: PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  }),
  credentialsString: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
};

export default BigPreceptorCard;
