import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import typeOfPracticeStyles from './styles';

const TypeOfPractice = ({ typeOfPractice, limit }) => {
  const classes = typeOfPracticeStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const types = limit !== undefined ? typeOfPractice.slice(0, limit) : typeOfPractice;

  return (
    <Box
      display={isMobile ? 'flex' : 'block'}
      flexDirection={isMobile ? 'column' : 'row'}
      alignItems={isMobile ? 'flex-start' : ''}
      maxWidth="100%"
    >
      {types.map(el => (
        <Box className={classes.communityBlock} key={el.key}>
          <Typography variant="subtitle2" className={classes.communityText}>
            {el.value}
          </Typography>
        </Box>
      ))}
      {(limit !== undefined && typeOfPractice.length > limit) && (
        <Typography variant="subtitle2" className={classes.tagCommunity} gutterBottom={isMobile}>
          +
          {typeOfPractice.length - limit}
        </Typography>
      )}
    </Box>
  );
};

TypeOfPractice.defaultProps = {
  typeOfPractice: [],
  limit: 4,
};

TypeOfPractice.propTypes = {
  limit: PropTypes.number,
  typeOfPractice: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })),
};

export default TypeOfPractice;
