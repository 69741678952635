import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import typography from 'theme/typography';

const sectionTagDateHoursStyles = makeStyles(theme => createStyles({
  link: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.icon,
  },
  label: {
    ...typography.subtitle3,
    color: theme.palette.text.secondary,
  },
  value: {
    ...typography.subtitle3,
  },
}));

export default sectionTagDateHoursStyles;
