import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import TextFieldLargeStyles from '../../../shared/styles/TextFieldLargeStyles';

const useStyles = makeStyles(() => ({
  TextFieldLargeStyles,
}));

const TextFields = () => {
  const classes = useStyles();
  return (
    <Box mt={4} mb={4}>
      <Typography variant="h5" align="center" gutterBottom>
        Text Field
      </Typography>
      <Grid container justify="center" alignItems="baseline" spacing={2}>
        <Grid item>
          <TextField
            label="Name"
            type="text"
            name="name"
            variant="filled"
            className={classes.TextFieldLargeStyles}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Name"
            type="text"
            name="name"
            variant="filled"
            size="medium"
          />
        </Grid>
        <Grid item>
          <TextField
            label="Name"
            type="text"
            name="name"
            variant="filled"
            size="small"
          />
        </Grid>
      </Grid>
      <Grid container justify="center" alignItems="baseline" spacing={2}>
        <Grid item>
          <TextField
            label="Name"
            type="text"
            name="name"
            variant="filled"
            size="large"
            defaultValue="John"
            error
            helperText="Error"
          />
        </Grid>
        <Grid item>
          <TextField
            label="Name"
            type="text"
            name="name"
            variant="filled"
            size="medium"
            disabled
          />
        </Grid>
        <Grid item>
          <TextField
            disabled
            id="filled-disabled"
            label="Name"
            defaultValue="John"
            variant="filled"
            size="small"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TextFields;
