import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const notAvailableStyles = makeStyles(theme => createStyles({
  block: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 12px',
    backgroundColor: theme.palette.colors.lightOrange,
    borderRadius: theme.spacing(1),
    minWidth: 230,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'max-content',
    },
  },
  right: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  notAvailable: {
    color: theme.palette.colors.red,
  },
  iconCancel: {
    marginRight: theme.spacing(1),
    color: theme.palette.colors.red,
  },
}));

export default notAvailableStyles;
