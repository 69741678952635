import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import typography from 'theme/typography';

const stressFreeBlockStyles = makeStyles(theme => createStyles({
  textBold: {
    fontWeight: 700,
  },
  greenBlock: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderRadius: 16,
    background: theme.palette.colors.powderyGreen,
    minHeight: 292,
  },
  blueBlock: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    borderRadius: 16,
    background: theme.palette.colors.lightBlue,
    minHeight: 292,
  },
  item: {
    listStyle: 'none',
    wordWrap: 'break-word',
    marginTop: theme.spacing(3),
    '& > li': {
      position: 'relative',
      '&:before': {
        position: 'absolute',
        left: '-13px',
        top: '12px',
        display: 'block',
        content: '" "',
        borderRadius: '50%',
        background: theme.palette.text.secondary,
        width: 3,
        height: 3,
      },
    },
  },
  hint: {
    color: theme.palette.text.hint,
    marginTop: theme.spacing(3.5),

  },
  link: {
    ...typography.subtitle1,
    color: theme.palette.colors.blueLink,
    '&:active': {
      color: theme.palette.colors.blueLink,
      underline: theme.palette.colors.blueLink,
    },
  },
  linkBold: {
    fontWeight: 700,
  },
  listItem: {
    color: '#4E6363',
    display: 'list-item',
    listStyle: 'disc',
    marginLeft: 40,
    paddingLeft: 0,
    paddingBottom: 0,
  },
}));

export default stressFreeBlockStyles;
