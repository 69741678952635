import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => createStyles({
  root: {
    width: '100vw',
    minHeight: 64,
    position: 'fixed',
    zIndex: 12,
    padding: '17px 41px 17px 24px',
    backgroundColor: theme.palette.backgrounds.backgroundBanner,
    borderBottom: '1px solid',
    borderBlockColor: theme.palette.colors.lightOrangeBorder,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'initial',
      minHeight: 'auto',
      position: 'static',
      padding: '15px 24px',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'initial',
    },
  },
  text: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      paddingLeft: 0,
    },
    '& p': {
      color: theme.palette.warning.dark,
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
      },
    },
  },
  button: {
    background: theme.palette.warning.light,
    '&:hover': {
      background: theme.palette.warning.main,
    },
    '&:active': {
      background: theme.palette.warning.main,
    },
  },
}));
