import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import palette from '../palette';

const breakpoints = createBreakpoints({});

export default {
  root: {
    fontWeight: 400,
    fontSize: '0.875rem',
    letterSpacing: '0.011em',
    lineHeight: 1.43,
    '&$focused': {
      color: `${palette.secondary.main} !important`,
    },
  },
  filled: {
    color: palette.text.secondary,
    transform: 'translate(12px, 16px) scale(1)',
    [breakpoints.down('sm')]: {
      transform: 'translate(12px, 8px) scale(1)',
      maxWidth: 'calc(100% - 24px)',
      fontSize: '0.8rem',
    },
    '&$marginDense': {
      transform: 'translate(12px, 10px) scale(1)',
    },
    '&$shrink': {
      fontWeight: 400,
      fontSize: '0.75rem',
      letterSpacing: '0.03333em',
      lineHeight: 1,
      transform: 'translate(12px, 10px) scale(1)',
      [breakpoints.down('sm')]: {
        transform: 'translate(12px, 6px) scale(1)',
        fontSize: '0.6rem',
      },
    },
    '&$disabled$shrink': {
      color: palette.grey['500'],
    },
    '&$shrink$error': {
      color: `${palette.error.main} !important`,
    },
  },
  asterisk: {
    color: palette.secondary.main,
  },
};
