import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import typography from 'theme/typography';

const amountBlockStyles = makeStyles(theme => createStyles({
  iconDollar: {
    color: theme.palette.secondary.main,
    borderRadius: 10,
  },
  blockDollar: {
    background: theme.palette.colors.powderyGreen,
    borderRadius: 10,
    padding: 6,
    marginRight: theme.spacing(2),
    display: 'flex',
  },
  text: {
    ...typography.subtitle3,
    color: theme.palette.text.secondary,
  },
}));

export default amountBlockStyles;
