import palette from '../palette';

export default {
  root: {
    backgroundColor: palette.grey[200],
  },
  text: {
    transform: 'scale (1, 1)',
  },
};
