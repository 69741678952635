import { createStyles, makeStyles } from '@material-ui/core/styles';
import typography from 'theme/typography';
import palette from 'theme/palette';

export default makeStyles(theme => createStyles({
  avatar: {
    background: theme.palette.secondary.main,
    borderRadius: '50%',
    height: 42,
    width: 42,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.icon,
    fontSize: 'medium',
    transition: 'transform 0.3s',
  },
  iconActive: {
    color: theme.palette.white,
    transform: 'rotate(180deg)',
  },
  iconAvatar: {
    color: theme.palette.white,
    marginRight: 0,
    filter: 'none',
    backgroundColor: theme.palette.secondary.main,
  },
  popover: {
    borderRadius: '8px',
    boxShadow: '0px 7px 24px rgba(26, 38, 38, 0.1)',
    border: `1px solid ${theme.palette.grey[400]}`,
    minWidth: 200,
  },
  buttonIcon: {
    '&:hover': {
      background: theme.palette.grey[50],
      '& $icon': {
        color: theme.palette.primary.main,
      },
    },
    // '&:active': {
    //   background: theme.palette.primary.main,
    //   color: theme.palette.white,
    //   '& $icon': {
    //     color: theme.palette.white,
    //   },
    //   '&:hover': {
    //     color: theme.palette.white,
    //     '& $icon': {
    //       color: theme.palette.white,
    //     },
    //   },
    // },
    '& a': {
      display: 'flex',
      color: 'inherit',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    [theme.breakpoints.down('md')]: {
      minHeight: '35px',
    },
  },
  name: {
    padding: '0 6px',
  },
  role: {
    display: 'flex',
    padding: '0 6px',
  },
  switchRole: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > $button': {
      paddingLeft: 0,
      textAlign: 'left',
    },
  },
  nameSwitch: {
    ...typography.subtitle3,
  },
  button: {
    padding: 0,
  },
  iconName: {
    color: theme.palette.icon,
  },
  statusItem: {
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: theme.palette.white,
    },
    '&:active': {
      backgroundColor: theme.palette.white,
    },
  },
  image: {
    borderRadius: '50%',
    height: '42px',
    width: '42px',
  },
  buttonSignOut: {
    border: `1px solid ${palette.grey['300']}`,
    color: palette.text.primary,
    background: palette.background.paper,
    fontSize: '0.938rem',
    lineHeight: '1.125em',
    fontWeight: 500,
    letterSpacing: '0.029em',
    padding: '12px 22px',
    borderRadius: '8px',
    '&:hover': {
      border: `1px solid ${palette.primary.light}`,
      background: palette.background.paper,
      color: palette.primary.main,
    },
    '&:active': {
      border: `1px solid ${palette.primary.light}`,
      background: palette.background.paper,
      color: palette.primary.main,
    },
  },
  textPrimary: {
    color: palette.text.primary,
  },
}));

