import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const newPasswordStyles = makeStyles(theme => createStyles({
  form: {
    border: `1px solid ${theme.palette.grey['300']}`,
    boxShadow: '0px 2px 4px rgba(23, 23, 23, 0.08)',
    borderRadius: '15px',
    padding: 36,
    maxWidth: '500px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      border: 'none',
      boxShadow: 'none',
      borderRadius: 0,
    },
  },
  text: {
    marginTop: 5,
  },
  logo: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
  },
}));

export default newPasswordStyles;
