import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Box, Grid, Button, FormHelperText, Checkbox, TextField, FormControlLabel,
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object().shape({
  email: yup.string().email('Email must be a valid email').required('Field is required'),
  password: yup.string().required('Field is required').min(8, 'Password is too short - should be 8 chars minimum'),
});

const SignInForm = ({ onSubmit, submitErrors }) => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const userMessage = useMemo(() => {
    if (submitErrors === 'auth/wrong-password') {
      return 'The password is invalid. Please try again.';
    }
    if (submitErrors === 'auth/user-not-found') {
      return 'This email is not registered. Please sign up.';
    }
    if (submitErrors === 'auth/user-disabled') {
      return 'This account has been disabled by the administrator. Please contact support.';
    }
    return submitErrors;
  }, [submitErrors]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoFocus
            autoComplete="email"
            // inputRef={register}
            {...register('email')}
            error={Boolean(errors.email?.message)}
            helperText={errors.email?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            // inputRef={register}
            {...register('password')}
            error={Boolean(errors.password?.message)}
            helperText={errors.password?.message}
          />
        </Grid>
      </Grid>
      <Box mb={1} ml={-1.5}>
        <FormControlLabel
          control={<Checkbox value="allowExtraEmails" color="primary" />}
          label="Remember Me"
        />
      </Box>
      {userMessage && (
        <Box mb={1}>
          <FormHelperText error>
            {userMessage}
          </FormHelperText>
        </Box>
      )}
      <Button type="submit" variant="contained" size="large" fullWidth>
        Login
      </Button>
    </form>
  );
};

SignInForm.defaultProps = {
  submitErrors: '',
};

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitErrors: PropTypes.string,
};

export default SignInForm;
