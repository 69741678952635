import React from 'react';
import { useHistory } from 'react-router';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import SearchPublicHeader from 'shared/components/SearchPublicHeader';
import EmailVerificationBanner from 'shared/components/EmailVerificationBanner';
import { useBackendUserState } from 'contexts/UserContext';
import { useAuth, useSigninCheck } from 'reactfire';
import clsx from 'clsx';
import paths from 'paths';
import userRoles from 'apps/account/constants/userRoles';
import SearchPublicLayoutStyles from './styles';

const SearchPublicLayout = ({ children }) => {
  const classes = SearchPublicLayoutStyles();
  const history = useHistory();
  const auth = useAuth();
  const { data: signInCheckResult } = useSigninCheck();
  const { state: { user: backendUser } = {} } = useBackendUserState();
  const emailVerified = backendUser?.emailVerified;
  const isUserAuthenticated = signInCheckResult.signedIn;

  const isWizardCompleted = backendUser?.wizardState?.isWizardCompleted;
  const showEmailBanner = isUserAuthenticated && isWizardCompleted && emailVerified === false;

  if (auth.currentUser && isWizardCompleted !== false && backendUser?.lastUsedRole?.key === userRoles.preceptor) {
    history.push(paths.rotationPaths.all.reverse());
  }

  return (
    <Box
      className={clsx(classes.root, {
        [classes.withEmailVerificationBanner]: showEmailBanner,
      })}
    >
      <SearchPublicHeader />
      {showEmailBanner && <EmailVerificationBanner />}
      {children}
    </Box>
  );
};

SearchPublicLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SearchPublicLayout;
