import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    alignItems: 'center',
    padding: `${theme.spacing(1)}px 0`,
  },
  text: {
    margin: '0 4px',
  },
  divider: {
    flexGrow: 1,
  },
}));
