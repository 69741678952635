import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import SNACKBAR_OPEN from 'actions/snackbarAction';
import { RESEND_VERIFICATION_LINK } from 'shared/queries/account/queries';
import Container from '@material-ui/core/Container';
import emailVerificationBannerStyles from './styles';
import { ReactComponent as Error1Icon } from '../../../assets/icons/error1.svg';

const EmailVerificationBanner = ({ maxWidth, top }) => {
  const classes = emailVerificationBannerStyles();
  const dispatch = useDispatch();
  const [resendVerificationLink, loading] = useMutation(RESEND_VERIFICATION_LINK);

  const handleResendVerificationEmail = () => {
    resendVerificationLink();
    dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      variant: 'alert',
      alertSeverity: 'success',
      message: 'Verification Email has been sent!',
    });
  };

  return (
    <Box className={classes.root} style={{ top }}>
      <Container maxWidth={maxWidth} className={classes.container} disableGutters={maxWidth === false}>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'center', md: 'flex-start' }}>
          <Error1Icon />
          <Box className={classes.text}>
            <Typography variant="body1">
              Please check your email and follow the link to verify your email address
            </Typography>
          </Box>
        </Box>
        <Button
          variant="contained"
          size="small"
          className={classes.button}
          onClick={handleResendVerificationEmail}
          loading={loading}
        >
          Resend Verification Email
        </Button>
      </Container>
    </Box>
  );
};

EmailVerificationBanner.defaultProps = {
  maxWidth: false,
  top: 75,
};

EmailVerificationBanner.propTypes = {
  maxWidth: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  top: PropTypes.number,
};

export default EmailVerificationBanner;
