import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const Title = ({ children }) => {
  const defaultTitle = 'PreceptorLink';

  return (
    <Helmet>
      <title>
        {children || defaultTitle}
        {' '}
        | PreceptorLink
      </title>
    </Helmet>
  );
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

const withTitle = (title = 'Uptime Controller') => ComposedComponent => props => (
  <>
    <Title>{title}</Title>
    <ComposedComponent {...props} />
  </>
);

export default withTitle;
