import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '75px',
    background: theme.palette.grey[100],
    [theme.breakpoints.down('md')]: {
      height: 'calc (100vh - 80px)',
    },
  },
  content: {
    flexGrow: 1,
    minHeight: 'calc(100vh - 157px)',
  },
  withEmailVerificationBanner: {
    paddingTop: '139px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '75px',
    },
  },
}));
